import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { AlertService } from '../../alerts/alert.service';
import { AlignResponsesService } from '../../services/align-responses.service';

@Component({
  selector: 'app-align-responses-edit-modal',
  templateUrl: 'align-responses-edit-modal.component.html',
})
export class AlignResponsesEditModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Submit';
  data: any = {};
  type: string;
  formModel: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private alignResponsesService: AlignResponsesService
  ) {}

  ngOnInit() {
    this.mapDataToForm();
    console.log(this.data.alignResponsesBlockObject.submission);
  }

  mapDataToForm() {
    // Make all included groups already checked
    if (typeof this.data.alignResponsesBlockObject.submission === 'undefined') {
      return false;
    }

    // Map all fields to data
    this.formModel.id = this.data.alignResponsesBlockObject.submission.student_quest_block_submission_match.Id;
    this.formModel.submission_id = this.data.alignResponsesBlockObject.submission.student_quest_block_submission_match.SubmissionId;
    this.formModel.first_name = this.data.alignResponsesBlockObject.submission.student_quest_block_submission_match.FirstName;
    this.formModel.last_name = this.data.alignResponsesBlockObject.submission.student_quest_block_submission_match.LastName;
  }

  submitForm() {
    this.alignResponsesService.updateResponse(this.formModel)
      .then(message => {
        this.alertService.show('Success! This response has been updated.', 'success');
        this.bsModalRef.hide();
        console.log(message);
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });

        this.bsModalRef.hide();
      });

    console.log(this.formModel);
  }
}
