import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default-no-sidebar.container.html'
})
export class DefaultNoSidebarContainer implements OnInit, OnDestroy {
  private user;
  public userData: any = {
    roles: [],
    organisation: {}
  };

  constructor(private userService: UserService, private authService: AuthService, private router: Router) {
  }

  public ngOnInit() {
    if (this.authService.checkLoggedIn()) {
      this.refresh();
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  public ngOnDestroy() {
    if (this.user) {
      this.user.unsubscribe();
    }
  }

  public refresh() {
    const user = this.userService.getLoggedInUser();

    this.user = user.subscribe((userData) => {
      this.userData = userData;
    });
  }
}
