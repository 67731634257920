import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { IndividualsService } from '../../services/individuals.service';
import { GroupsService } from '../../services/groups.service';
import { AlertService } from '../../alerts/alert.service';
import { QuestblocksService } from '../../services/questblocks.service';

@Component({
  selector: 'app-quest-block-modal',
  templateUrl: 'quest-block-modal.component.html',
})
export class QuestBlockModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Submit';
  data: any = {};
  type: string;
  formModel: any = {};
  groups: any = [];
  selectedGroups = [];

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private groupsService: GroupsService,
    private questBlocksService: QuestblocksService
  ) {}

  ngOnInit() {
    this.mapDataToForm();
    this.createSelectedGroupsArray();
    this.refresh();
  }

  refresh() {
    const selectedGroups = this.selectedGroups;
    this.groupsService.getGroups()
      .then(groups => {
        groups.forEach(function (value, idx) {
          if (selectedGroups.includes(value.id)) {
            groups[idx].checked = true;
          } else {
            groups[idx].checked = false;
          }
        });

        this.formModel.rumbles_groups = groups;
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });
      });
  }

  mapDataToForm() {
    // Make all included groups already checked
    const rumblesGroups = [];
    this.data.questBlockObject.rumbles_groups.forEach(function (value, idx) {
      value.checked = true;
      rumblesGroups.push(value);
    });
    this.data.questBlockObject.rumbles_groups = rumblesGroups;

    // Map all fields to data
    this.formModel.QuestBlockId = this.data.questBlockObject.QuestBlockId;
    this.formModel.QuestBlockKey = this.data.questBlockObject.QuestBlockKey;
    this.formModel.Title = this.data.questBlockObject.Title;
    this.formModel.Comment = this.data.questBlockObject.Comment;
    this.formModel.ProductionFlag = this.data.questBlockObject.ProductionFlag;
    this.formModel.rumbles_groups = this.data.questBlockObject.rumbles_groups;
  }

  submitForm() {
    // Only include checked results
    this.formModel.rumbles_groups = this.formModel.rumbles_groups.filter(value => value.checked);

    this.questBlocksService.updateOrCreateQuestBlock(this.type, this.formModel)
      .then(message => {
        this.alertService.show('Success! This Quest Block has been created/updated.', 'success');
        this.bsModalRef.hide();
        console.log(message);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.bsModalRef.hide();
      });

    console.log(this.formModel);
  }

  createSelectedGroupsArray() {
    const selected = [];
    this.data.questBlockObject.rumbles_groups.forEach(function (value) {
      selected.push(value.id);
    });
    this.selectedGroups = selected;
  }
}
