import { Injectable } from '@angular/core';
import { SnapshotModel } from '../models/SnapshotModel';
import { Store, select } from '@ngrx/store';
import { ADD_PARTICIPANT_TAGS } from '../reducers/participant-tags-reducer';
import { ApiService } from './api.service';

interface AppState {
  participantTags: any;
}

@Injectable()
export class ReportsService {
  constructor(
    private readonly apiService: ApiService,
    private store: Store<AppState>
  ) {}

  public getAssociatedParticipants(): Promise<any> {
    return this.apiService.get<any>(`coalition/survey`).toPromise<any>();
  }

  public getReportingRange(): Promise<any> {
    return this.apiService.get<any>(`coalition/survey/reporting-range/get`).toPromise<any>();
  }

  public getAll(): Promise<any> {
    return this.apiService.get<any>(`coalition/survey`).toPromise<any>();
  }

  public get(accessCode: string): Promise<any> {
    return this.apiService.get<any>(`coalition/survey/${accessCode}`).toPromise<any>();
  }

  public getSnapshot(): Promise<SnapshotModel> {
    return this.apiService.get<SnapshotModel>(`coalition/snapshot`).toPromise<SnapshotModel>();
  }

  public create(email: string | string[]): Promise<any> {
    return this.apiService.post<any>(`coalition/survey`, {
      email: email
    }).toPromise<any>();
  }

  public send(email: string, type: string, message?: string): Promise<any> {
    return this.apiService.post<any>(`coalition/survey/send`, {
      email: email,
      type: type,
      message: message
    }).toPromise<any>();
  }

  public setReportingRange(id: number, name: string, date_start: string, date_end: string): Promise<any> {
    return this.apiService.post<any>(`coalition/survey/reporting-range/create`, {
      id: id,
      name: name,
      date_start: date_start,
      date_end: date_end
    }).toPromise<any>();
  }

  public createTagsForParticipants(participantsArray: Array<number>, name: string, value: string): Promise<any> {
    return this.apiService.post<any>(`coalition/participants/tags/add`, {
      participantsArray: participantsArray,
      name: name,
      value: value
    }).toPromise<any>();
  }

  public deleteParticipantTagById(participantId: number, tagId: number): Promise<any> {
    return this.apiService.delete<any>(`coalition/participants/tags/delete/${tagId}`, {
      tagId: tagId,
      participantId: participantId
    }).toPromise<any>();
  }

  public delete(email: string): Promise<any> {
    return this.apiService.delete<any>(`coalition/survey`, {
      email: email
    }).toPromise<any>();
  }

  public save(accessCode: string, model: any): Promise<any> {
    return this.apiService.put<any>(`coalition/survey/${accessCode}`, model).toPromise<any>();
  }

  public getTagsFromApiAndSet() {
    let tags = [];

    this.getAssociatedParticipants()
      .then(response => {
        console.log(response);
        // participant tags for a loop (filter to remove empty, then map non-empty)
        response.forEach(function(item, index) {
          if (item.participant.tags.length === 0) {
            return;
          }

          tags = tags.concat(item.participant.tags);
        });

        const filteredNames = Array.from(new Set(tags.map((itemInArray) => itemInArray.name)));

        this.setTags({ filteredNames: filteredNames, tags: tags });
      })
      .catch(error => {
        console.log(error);
        // const jsonRes = JSON.parse(error._body);

        // this.error = jsonRes.message;
        //    this.loading = false;
      });
  }

  public setTags(tagsArr: object) {
    this.store.dispatch({
      type: ADD_PARTICIPANT_TAGS,
      payload: tagsArr
    });
  }

  public getTagsFromLocal() {
    const tags = localStorage.getItem('participantTags');

    if (tags === null) {
      return null;
    }

    return JSON.parse(tags);
  }

}
