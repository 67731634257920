import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html'
})

export class LoaderComponent implements OnInit, OnDestroy {
  show = false;

  private subscription: Subscription;

  constructor (private loaderService: LoaderService, private cdRef : ChangeDetectorRef) {
    //
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        const show = state.show;

        if (show !== this.show) { // check if it change, tell CD update view
          this.show = show;
          this.cdRef.detectChanges();
        }
      });
   }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
