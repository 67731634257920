import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { colors } from '../constants/colors.constant';
import { TranslationService } from '../services/translation.service';
import { Store } from '@ngrx/store';
import { ReportingService } from '../services/reporting.service';
import { MatTableDataSource } from '@angular/material';
import { QuestBlockFilteringService } from './quest-block-filtering.service';
import { Subscription } from 'rxjs/Rx';
import { AlertService } from '../alerts/alert.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-assets-report',
  templateUrl: 'assets-report.component.html'
})
export class AssetsReportComponent implements OnInit, OnDestroy {
  public questBlocks = [];
  public assetDataLoading = false;
  public viewKeyHighlighted = false;
  public showKeyHighlightChildrenFunction = {};
  // public showKeyHighlightChildrenFunction = this.viewWho;
  private questBlockFilteringSubscription: Subscription;
  public translations: any = {};
  public keyHighlightData: any = {
    name: '',
    description: '',
    data: [],
    loading: false,
    showQuestionsFor: [],
    showChosenStudent: false,
    chosenStudent: {}
  };
  public assetsData: any = {
    filteredAssets: <any>[],
    overallAssets: <any>{}
  };
  public assets = [
    {
      key: 'commitment-to-learning',
      assetDataKey: 'commitmentToLearning',
      name: 'Commitment to Learning',
      url: '/report/assets-report/commitment-to-learning',
    },
    {
      key: 'positive-values',
      assetDataKey: 'positiveValues',
      name: 'Positive Values',
      url: '/report/assets-report/positive-values',
    },
    {
      key: 'positive-identity',
      assetDataKey: 'positiveIdentity',
      name: 'Positive Identity',
      url: '/report/assets-report/positive-identity',
    },
    {
      key: 'safe-protective-environment',
      assetDataKey: 'safeProtectiveEnvironment',
      name: 'Safe Protective Environment',
      url: '/report/assets-report/safe-protective-environment',
    },
  ];
  public pageAsset: any;
  private sub: any;
  public summaryGraph = {
    colors: [
      colors.high,
      colors.medium,
      colors.low,
    ],
    breakdownGraphColors: [
      colors.high,
      colors.modHigh,
      colors.moderate,
      colors.lowMod,
      colors.low,
    ],
    categories: [
      'High',
      'Med-High',
      'Medium',
      'Low-Med',
      'Low',
    ],
    keyHighlights: [],
    series: [
      {
        data: []
      },
    ],
  };
  public permissions = [];

  constructor(
    private store: Store<any>,
    private questBlockFilteringService: QuestBlockFilteringService,
    private translationService: TranslationService,
    private reportingService: ReportingService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();

    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlockFilter !== 'undefined') {
        this.questBlocks = val.questBlockFilter;
        this.refresh(); // refresh graph on change of quest block
      }
    });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.pageAsset = {
        key: params['asset'],
        name: params['asset'],
      };

      for (let i = 0; i < this.assets.length; i++) {
        if (this.assets[i].key === params['asset']) {
          this.pageAsset = this.assets[i];
          return;
        }
      }
    });
    this.questBlocks = this.questBlockFilteringService.questBlocks;
    this.refresh(); // init load
  }

  ngOnDestroy() {
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public refresh() {
    if (this.questBlocks.length === 0) {
      return; // only if quest blocks are set should it be run
    }

    this.assetDataLoading = true;

    const requestParams = [];

    this.questBlocks.forEach(function (val) {
      requestParams[val.name] = val.value;
    });

    // Reset key highlighted data
    this.resetKeyHighlightedObject();

    this.reportingService.getAssetsReport(requestParams)
      .then(report => {
        this.assetDataLoading = false;
        if (typeof report.assetsData !== 'undefined' && report.assetsData.length === 0) {
          this.alertService.show('No assets data found for this quest block, please choose another.', 'danger');
          return;
        }
        this.assetsData = report.assetsData;
        console.log(this.assetsData);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.assetDataLoading = false;
      });
  }

  public toggleKeyHighlighted($event) {
    this.viewKeyHighlighted = $event;
  }

  public getStudentsForViewWho($event) {
    const questBlocKeys = [];
    const __this = this;

    // Reset key highlighted data
    this.resetKeyHighlightedObject();

    this.keyHighlightData.loading = true;

    this.questBlocks.forEach(function (val: any, index) {
      questBlocKeys.push(val.value);
    });

    const requestParams = {
      questBlockKeys: questBlocKeys.join(),
      questionId: $event.questionId,
      scoreBandArray: $event.scoreBandArray.join(),
      asset: this.pageAsset.assetDataKey,
    };

    // get score band 0 & 1
    // home = Q9
    // school = Q10

    this.reportingService.getStudentsByQuestionId(requestParams)
      .then(report => {
        if (typeof report.assetsData !== 'undefined' && report.assetsData.length === 0) {
          __this.alertService.show('No assets data found for this quest block, please choose another.', 'danger');
          return;
        }

        __this.keyHighlightData.loading = false;
        __this.keyHighlightData.name = $event.highlightObject.see_who_heading;
        __this.keyHighlightData.showQuestionsFor = $event.highlightObject.see_who_individual_questions;
        __this.keyHighlightData.description = $event.highlightObject.see_who_description;
        __this.keyHighlightData.data = report;
      })
      .catch((msg) => {
        console.log(msg);
        __this.alertService.show(msg.error.message, 'danger');
      });
  }

  public viewKeyHighlightedIndividual(index) {
    this.keyHighlightData.showChosenStudent = true;
    this.keyHighlightData.chosenStudent = this.keyHighlightData.data[index];
    console.log(this.keyHighlightData.data[index]);

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById('keyHighlightedIndividual').offsetTop
    });
  }

  public resetKeyHighlightedObject() {
    // Reset key highlighted data
    this.keyHighlightData = {
      name: '',
      description: '',
      data: [],
      loading: false,
      showQuestionsFor: [],
      showChosenStudent: false,
      chosenStudent: {}
    };
  }
}
