import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationExtras } from '@angular/router';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})

export class RegisterComponent implements OnInit {
  model: any = {};
  loading = false;
  error: any = '';

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private readonly router: Router
  ) { }

    ngOnInit() {
      // reset login status
      this.authService.logout();
    }

    register() {
      this.loading = true;

      this.userService.register(this.model)
        .then(response => {
          this.loading = false;
          const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
            queryParams: {
              success: 'You have successfully registered. Please login below.'
            },
          };

          this.router.navigate(['/auth/login'], navigationExtras);
        })
        .catch(error => {
          const jsonRes = JSON.parse(error._body);

          if (typeof jsonRes.errors !== 'undefined') {
            this.error = jsonRes.errors;
            console.log(this.error);
          } else {
            this.error = jsonRes.message;
          }

          this.loading = false;
        });
    }
}
