import { Component, Input, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { QuestBlockFilteringService } from './quest-block-filtering.service';
import {
  OVERRIDE_BULK_QUEST_BLOCK,
  RESET_QUEST_BLOCK
} from '../reducers/quest-block-filtering-reducer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestblocksService } from '../services/questblocks.service';
import { MatTableDataSource } from '@angular/material';
import { AlertService } from '../alerts/alert.service';

interface AppState {
  addedQuestBlockArray: Array<object>;
  questBlocks: any;
}

@Component({
  selector: 'app-quest-block-filtering',
  templateUrl: 'quest-block-filtering.component.html'
})

export class QuestBlockFilteringComponent implements OnInit, OnDestroy {
  public checkedQuestBlocks = [];
  public checkboxGroup = new FormGroup({});

  private subscription: Subscription;
  private questBlockFilteringSubscription: Subscription;
  public questBlocks: any = [];

  public addedQuestBlockArray$: Observable<Array<object>>;
  public questBlocks$: Observable<any>;
  public modalRef: BsModalRef;

  constructor (
    private questBlockFilteringService: QuestBlockFilteringService,
    private questBlocksService: QuestblocksService,
    private reportsService: ReportsService,
    private store: Store<AppState>,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {
    this.addedQuestBlockArray$ = store.pipe(select('addedQuestBlockArray'));
    this.questBlocks$ = store.pipe(select('questBlocks'));

    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlocks !== 'undefined' && Object.keys(val.questBlocks).length > 0) {
        // this.questBlocks = val.questBlocks;
      }
    });
  }

  refresh() {
    this.questBlocksService.getQuestBlocks()
      .then(questBlocks => {
        // Push array of checked values
        this.questBlocks = questBlocks;
        this.questBlocks.forEach(value => {
          this.checkboxGroup.addControl(value.QuestBlockKey, new FormControl(this.checkedQuestBlocks.indexOf(value.QuestBlockKey) !== -1));
        });
      })
      .catch((msg) => {
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  selectChosenFilters() {
    const checkedValues = [];

    // clear current checked values in store
    this.clearChosenFilterToTagsArray();

    // Get checked values
    const storeDispatch = [];
    Object.keys(this.checkboxGroup.controls).forEach(key => {
      const obj = this.checkboxGroup.controls[key];
      const questBlockCurrent = this.questBlocks.find(function (val) {
        return val.QuestBlockKey === key;
      });
      if (obj.value === true) {
        checkedValues.push(key);
        storeDispatch.push({
          name: key,
          value: key,
          full: questBlockCurrent,
        });
      }
    });

    // Push array of checked values
    this.store.dispatch({
      type: OVERRIDE_BULK_QUEST_BLOCK,
      payload: storeDispatch
    });

    this.modalRef.hide();
  }

  clearChosenFilterToTagsArray() {
    this.store.dispatch({
      type: RESET_QUEST_BLOCK
    });
  }
}
