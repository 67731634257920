import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { throwError } from 'rxjs';

@Injectable()

export class AuthService {

  public jwtHelper: JwtHelperService = new JwtHelperService();
  private uatUrl = `${environment.apiEndpoint}/oauth/token`;
  private clientSecret = 'B0pgByH1LCwwDhhiNzDCKTmg00xeU2D9rjR7eDYO';
  private clientId = 1;
  public allowedLoggedOutPages = [
    '/survey/',
    '/consent/',
    '/nsw-consent/',
    '/create-new-user/',
  ];

  constructor(private http: HttpClient, private readonly router: Router) {
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  public getTokenOffset(): string {
    return localStorage.getItem('token_offset');
  }

  public getUserProfile(): any {
    const userProfile = localStorage.getItem('user_profile');
    return JSON.parse(userProfile);
  }

  public getOrganisationType(): string {
    const userProfile = this.getUserProfile();

    if (userProfile === null) {
      return null;
    }

    return userProfile.organisation.type;
  }

  public getAccessToken(email: string, password: string) {
    const postData = {
      grant_type: 'password',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      username: email,
      password: password,
      scope: '*'
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      body: postData,
      method: 'POST'
    };

    return this.http.post(this.uatUrl, postData, options).toPromise<any>();
  }

  public postResetPassword(email: string) {
    const postData = {
      email: email,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      body: postData,
      method: 'POST'
    };

    return this.http.post(`${environment.apiEndpoint}/api/rumbles/reset-password`, postData, options).toPromise<any>();
  }

  public postConfirmEmailByKey(token: string) {
    const postData = {
      token: token,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }),
      body: postData,
      method: 'POST'
    };

    return this.http.post(`${environment.apiEndpoint}/api/confirm-email`, postData, options).toPromise<any>();
  }

  /**
   * Get New Access Token
   * @returns {Observable<Object>}
   */
  public getNewAcessToken(): Observable<Object> {
    const postData = {
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken(),
      client_id: this.clientId,
      client_secret: this.clientSecret,
      scope: '*'
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      body: postData,
      method: 'POST'
    };

    return this.http.post(this.uatUrl, postData, options);
  }

  logout(error = null) {
    let errorMessage = 'You have been logged out due to an expired session. Please login below';

    localStorage.clear();

    if (error) {
      errorMessage = error;
    }

    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        error: errorMessage
      },
    };

    const allowed = this.allowedLoggedOutPages.filter(page => window.location.pathname.indexOf(page) > 0).length > 0;

    if (!allowed && window.location.pathname !== '/auth/register' && window.location.pathname !== '/auth/login') {
      console.log('Logging out...');
      this.router.navigate(['/auth/login'], navigationExtras);
    }
  }

  checkLoggedIn() {
    const authToken = this.getToken();

    if (authToken === null || authToken === 'undefined') {
      return false;
    }

    return true;
  }
}
