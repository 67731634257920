import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertState } from './alert';

@Injectable()

export class AlertService {
  private alertSubject = new Subject<AlertState>();

  alertState = this.alertSubject.asObservable();

  constructor() { }

  show(message: string, type: string) {
    this.alertSubject.next(<AlertState>{ show: true, message: message, type: type });
  }

  hide() {
    this.alertSubject.next(<AlertState>{ show: false, message: '', type: '' });
  }
}
