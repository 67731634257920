import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.container.html'
})

export class AuthContainer {
  constructor() {}
}
