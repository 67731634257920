export const colors = {
  thriving: '#24a559',
  thrivingDark: '#5ed297',
  ontrack: '#b2def7',
  ontrackDark: '#789ab9',
  atrisk: '#f8c55f',
  atriskDark: '#f8c65e',
  vulnerable: '#e57452',
  vulnerableDark: '#b96c4f',
  population: '#f29b11',
  groupMedian: '#1e8bc3',
  high: '#35bc6c',
  modHigh: '#1097c1',
  moderate: '#0f526a',
  medium: '#58abe3',
  lowMod: '#009344',
  low: '#e57452'
};
