import { Component, OnDestroy, OnChanges, Input } from '@angular/core';

import { colors } from '../constants/colors.constant';

import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-questions-response-ranking-bar-chart',
  templateUrl: './questions-response-ranking-bar-chart.component.html'
})

export class QuestionsResponseRankingBarComponent implements OnChanges {
  @Input() public meanRating: any;
  @Input() public tooltipEnabled = false;
  @Input() public dataLabelsEnabled = false;
  @Input() public categories: Array<string>;
  @Input() public colors: Array<string>;
  @Input() public series: Array<object>;

  public chart: Chart;

  public ngOnChanges() {
    this.renderChart();
  }

  private renderChart() {
    // if (!this.meanRating) {
    //   return;
    // }

    this.chart = new Chart({
      chart: {
        type: 'bar',
        height: 25,
        spacing: [0, 0, 0, 0],
        marginTop: -20,
        marginBottom: -20
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: this.tooltipEnabled,
        // pointFormat: '<span style="color:{series.color}">{series.name}</span>',
        // shared: true,
        // borderWidth: 0,
        // backgroundColor: '#fff',
        useHTML: true,
        animation: true,
        // padding: 10,
        // headerFormat: '<b>{point.x}</b><br/>'
        // formatter: function () {
        //   return '<b>' + this.userOptions.name + '</b>';
        // }
      },
      xAxis: {
        lineColor: 'transparent',
        gridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          enabled: false
        },
        title: {
          text: null
        }
      },
      yAxis: {
        lineColor: 'transparent',
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false
        },
        title: {
          text: null
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        bar: {
          stacking: 'percent',
          dataLabels: {
            inside: true
          }
        },
        series: {
          dataLabels: {
            enabled: this.dataLabelsEnabled,
            align: 'center',
            x: 3,
            verticalAlign: 'middle',
            crop: false,
            color: '#fff',
            shadow: false,
            borderWidth: 0,
            useHTML: true,
            style: {
              textOutline: '0',
              fontWeight: 'normal'
            },
            format: '{point.percentage:.0f}%'
          }
        }
      },
      colors: this.colors,
      series: this.series,
    });
  }
}
