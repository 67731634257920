import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class PermissionsService {
  public permissions = [];

  constructor(private authService: AuthService) {}

  /**
   * Get Permissions
   * @returns Array<string>
   */
  public getPermissions(): Array<string> {
    const userProfile = this.authService.getUserProfile();

    if (userProfile === null) {
      return [];
    }

    return Object.keys(userProfile.permissions);
  }
}
