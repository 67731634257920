import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnapshotModel } from './models/SnapshotModel';
import { ReportsService } from './services/reports.service';
import { UserService } from './services/user.service';
import { AlertService } from './alerts/alert.service';
import { QuestBlockFilteringService } from './reports/quest-block-filtering.service';
import { Subscription } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { TranslationService } from './services/translation.service';
import { PermissionsService } from './services/permissions.service';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html'
})
export class SnapshotComponent implements OnDestroy {
  public model = new SnapshotModel();

  name;
  email;
  organisation;
  cfc_name;

  private user;
  private questBlockFilteringSubscription: Subscription;
  public questBlocks = [];
  public permissions = [];
  public translations: any = {};

  constructor(
    private store: Store<any>,
    private reportsService: ReportsService,
    private userService: UserService,
    private alertService: AlertService,
    private questBlockFilteringService: QuestBlockFilteringService,
    private translationService: TranslationService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();

    // this.getSnapshot();
    this.getUser();

    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlockFilter !== 'undefined') {
        this.questBlocks = val.questBlockFilter;
      }
      console.log(val);
    });
  }

  ngOnDestroy() {
    if (this.user) {
      this.user.unsubscribe();
    }
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }

  getUser() {
    const userSub = this.userService.getLoggedInUser();
    const userProfile = localStorage.getItem('user_profile');

    this.user = userSub.subscribe((user) => {
      if (userProfile === null || userProfile === 'undefined') {
        localStorage.setItem('user_profile', JSON.stringify(user));
      }

      this.name = user.name;
      this.email = user.email;
      this.organisation = user.ccp_organisation;
      this.cfc_name = user.ccp_name;
    });
  }
}
