import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ReportsService } from '../services/reports.service';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ADD_REPORT_FILTERING, PUSH_REPORT_FILTERING, REMOVE_REPORT_FILTERING, RESET_REPORT_FILTERING } from '../reducers/report-filtering';
import { ADD_PARTICIPANT_TAGS } from '../reducers/participant-tags-reducer';
import { ReportFilteringService } from './report-filtering.service';

interface AppState {
  addedTagsArray: Array<object>;
  participantTags: any;
}

@Component({
    selector: 'app-report-filtering',
    templateUrl: 'report-filtering.component.html'
})

export class ReportFilteringComponent implements OnInit, OnDestroy {
  public chosenTagName = '';
  public chosenTagVal = '';
  public valuesForChosenTagName = [];

  private subscription: Subscription;
  private reportFilteringSubscription: Subscription;
  private participantTags: any = [];

  addedTagsArray$: Observable<Array<object>>;
  participantTags$: Observable<any>;

  constructor (
    private reportFilteringService: ReportFilteringService,
    private reportsService: ReportsService,
    private store: Store<AppState>
  ) {
    this.addedTagsArray$ = store.pipe(select('addedTagsArray'));
    this.participantTags$ = store.pipe(select('participantTags'));

    // Subscribe to store and store in public property
    this.reportFilteringSubscription = this.store.subscribe(val => {
      console.log(val);
      if (typeof val.participantTags !== 'undefined') {
        this.participantTags = val.participantTags;
      }
    });
  }

  refresh() {
    const participantTags = this.reportsService.getTagsFromLocal();

  	// get tags from local, set them otherwise get new ones.
    if (participantTags) {
      this.reportsService.setTags(participantTags);
    } else {
      this.reportsService.getTagsFromApiAndSet();
    }
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.reportFilteringSubscription) {
      this.reportFilteringSubscription.unsubscribe();
    }
  }

  updateChosenTagName(value) {
    this.chosenTagName = value;

    // loop over and filter where name doesnt include
    let valuesForChosenName = this.participantTags.tags
        .filter(function (item) {
          if (item.name !== value) {
            return false;
          }

          return true;
        })
        .map(function (item) {
          return item.value;
        });

    // remove duplicate values
    valuesForChosenName = Array.from(new Set(valuesForChosenName));

    this.valuesForChosenTagName = valuesForChosenName;
  }

  updateChosenTagVal(value) {
    this.chosenTagVal = value;
  }

  addChosenFilterToTagsArray() {
    const payload = {
      name: this.chosenTagName,
      value: this.chosenTagVal
    };

    let skip = false;

    this.reportFilteringService.addedTagsArray.forEach(function (value: any, key: number) {
       if (value.name === payload.name && value.value === payload.value) {
         skip = true;
       }
    });

    if (skip) {
      return;
    }

    this.store.dispatch({
      type: PUSH_REPORT_FILTERING,
      payload: payload
    });

    // Reset values
    this.chosenTagName = '';
    this.chosenTagVal = '';
    this.valuesForChosenTagName = [];
  }

  clearChosenFilterToTagsArray() {
    this.store.dispatch({
      type: RESET_REPORT_FILTERING
    });
  }
}
