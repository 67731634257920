import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { IndividualsService } from '../../services/individuals.service';
import { GroupsService } from '../../services/groups.service';
import { AlertService } from '../../alerts/alert.service';
import { Papa } from 'ngx-papaparse';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-individual-bulk-import',
  templateUrl: 'individual-bulk-import.component.html',
})
export class IndividualBulkImportComponent {
  title: string;
  closeBtnName = 'Close';
  backBtnName = 'Back Step';
  submitBtnName = 'Import/Update';
  step = 1;
  data: any = {};
  errors = [];
  type: string;
  formModel: any = {};
  groups: any = [];
  form = {
    csv: new FormControl(),
    headerRow: new FormControl()
  };
  JSON = JSON;
  validationErrors: string[] = [];
  csvContent: string;
  csvContentArray: object[] = [];
  csvContentArrayKeys: string[] = [];
  finalObjectToPost: any[] = [];
  indigenousImportUniqueValues: string[] = [];
  genderImportUniqueValues: string[] = [];
  moment: any;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private groupsService: GroupsService,
    private individualsService: IndividualsService,
    private papa: Papa
  ) {
    this.moment = moment;
    this.moment.locale('en-AU');
  }

  /**
   * Store CSV file into this.csvContent
   * @param {HTMLInputElement} input
   */
  onFileSelect(input: HTMLInputElement) {
    const files = input.files;

    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      const __this = this;

      fileReader.onload = function(fileLoadedEvent: any) {
        console.log(fileLoadedEvent.target.result);
        __this.csvContent = fileLoadedEvent.target.result;
      };

      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  backStep() {
    if (this.step > 1) {
      this.step--;
    }
  }

  submitForm() {
    if (this.step === 1) {
      this.parseCSV();
    } else if (this.step === 2) {
      this.mapFields();
      const valid = this.validateFields();
      window.scrollTo(0, 0);

      if (valid) {
        this.step++;
      }
    } else if (this.step === 3) {
      this.submitUpload();
    }
  }

  /**
   * Step 2: Validate required fields & types (eg date format)
   *
   * @returns {boolean}
   */
  validateFields() {
    let valid = true;
    const __this = this;
    console.log(this.finalObjectToPost);

    // validate values duplicates on the this.formModel (might be able to do on change)
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };

    // Don't use Object.values as this is an experimental feature that does not work in IE
    // https://stackoverflow.com/a/43255030/4197938
    const objValues = Object.keys(this.formModel).map(itm => this.formModel[itm]);

    const duplicatesValidation = objValues.filter(unique).length === objValues.length;
    if (!duplicatesValidation) {
      this.validationErrors.push('Duplicates in mapping error: One or more values you mapped aren\'t unique. Check that all values chosen from dropdowns are unique. Fix CSV and re-import.');
      valid = false;
    }

    this.finalObjectToPost = this.finalObjectToPost.map(function (obj) {
      obj.dateOfBirth = __this.moment(obj.dateOfBirth.replace(/[\s -]+/g, '/'), 'DD/MM/YYYY');
      obj.dateOfBirth = __this.moment(obj.dateOfBirth).format('DD-MM-YYYY');
      return obj;
    });

    // date of birth validation
    // console.log(moment(this.finalObjectToPost[0].dateOfBirth));
    // console.log(moment(this.finalObjectToPost[0].dateOfBirth).format('DD-MM-YYYY'));
    // console.log(moment(this.finalObjectToPost[0].dateOfBirth).isValid());
    const dateValidation = this.finalObjectToPost.filter(obj => !this.moment(obj.dateOfBirth, 'DD/MM/YYYY').isValid());
    console.log(dateValidation);
    if (dateValidation.length > 0) {
      this.validationErrors.push('Date of Birth Format Error: One or more of the formats for the date of birth. Required format: DD/MM/YYYY (30/02/2010) or DD-MM-YYYY (30-02-2010).');
      valid = false;
    }

    // grade must be integer between 0 and 7 (0 is prep)
    const gradeValidation = this.finalObjectToPost.filter(obj => isNaN(obj.grade) || obj.grade < 0 || obj.grade > 8);
    if (gradeValidation.length > 0) {
      this.validationErrors.push('Year error: One or more rows in this CSV are not numbers. Ensure all rows are *only* numbers, not less than 0, and not more than 8. Fix CSV and re-import.');
      valid = false;
    }
    console.log(gradeValidation);

    // Not empty validation
    let notEmptyValidation = false;
    this.finalObjectToPost.forEach(function (obj) {
      notEmptyValidation = !Object.keys(obj).map(itm => obj[itm]).every((val) => {
        return val !== null && val !== '';
      });

      if (notEmptyValidation) {
        return false;
      }
    });
    if (notEmptyValidation) {
      this.validationErrors.push('Empty value error: One or more of the fields are empty. You cannot have an empty field. Fix CSV and re-import.');
      valid = false;
    }

    console.log(notEmptyValidation);

    return valid;
  }

  mapFields() {
    const __this = this;

    // Map fields to what we want
    this.finalObjectToPost = this.csvContentArray.map(function (val, index) {
      // Gender map to M/F/-
      const genderVal = val[__this.formModel.gender] === __this.formModel.genderMale ? 'male'
        : val[__this.formModel.gender] === __this.formModel.genderFemale ? 'female'
          : 'unknown';

      // Indigenous map to 1/0
      const indigenousVal = val[__this.formModel.indigenous] === __this.formModel.indigenousYes ? 1
        : val[__this.formModel.indigenous] === __this.formModel.indigenousNo ? 0
          : 0;

      return {
        firstName: val[__this.formModel.firstName],
        lastName: val[__this.formModel.lastName],
        dateOfBirth: val[__this.formModel.DOB],
        studentId: val[__this.formModel.studentId],
        gender: genderVal,
        grade: val[__this.formModel.grade],
        group: val[__this.formModel.group],
        indigenous: indigenousVal // todo: map answer using indigenousYes and indigenousNo, with fallback
      };
    });
  }

  submitUpload() {
    console.log(this.finalObjectToPost);
    this.individualsService.bulkImportIndividuals(this.finalObjectToPost)
      .then(message => {
        this.alertService.show('Success! CSV Has been successfully imported. Refreshing page...', 'success');
        console.log(message);
        this.bsModalRef.hide();
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });
        this.bsModalRef.hide();
      });
  }

  /**
   * Step 1: Parse CSV & Determine Errors
   */
  parseCSV() {
    const headerRow = !!this.formModel.headerRow; // true if set
    console.log(headerRow);

    this.papa.parse(this.csvContent, {
      skipEmptyLines: true,
      header: headerRow,
      complete: (results, file) => {
        this.errors = [];
        const __this = this;

        // Handle errors
        if (results.errors.length > 0) {
          results.errors.forEach(function (val: any) {
            __this.errors.push(val.message);
          });
          return true;
        }

        if (results.data.length > 0 && Array.isArray(results.data[0])) {
          __this.errors.push('Header row checkbox had incorrect value. Please toggle to opposite value and try again.');
          return true;
        }

        console.log(results);
        this.csvContentArray = results.data;
        this.csvContentArrayKeys = Object.keys(this.csvContentArray[0]);
        this.step = 2;
      }
    });
  }

  onChange(field, keyName) {
    const __this = this;

    if (field === 'gender') {
      __this.genderImportUniqueValues = [];
    } else if (field === 'indigenous') {
      console.log('pushed');
      __this.indigenousImportUniqueValues = [];
    }

    this.csvContentArray.forEach(function (val, index) {
      console.log(val);
      console.log(index);

      // check key exists
      if (keyName in val) {
        console.log(val[keyName]);
        if (field === 'gender') {
          __this.genderImportUniqueValues.push(val[keyName]);
        } else if (field === 'indigenous') {
          console.log('pushed');
          __this.indigenousImportUniqueValues.push(val[keyName]);
        }
      }
    });

    // create unique array
    this.indigenousImportUniqueValues = this.indigenousImportUniqueValues.filter((v, i, a) => a.indexOf(v) === i);
    this.genderImportUniqueValues = this.genderImportUniqueValues.filter((v, i, a) => a.indexOf(v) === i);
  }
}
