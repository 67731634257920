import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AlertService } from '../alerts/alert.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { QuestblocksService } from '../services/questblocks.service';
import { GroupsService } from '../services/groups.service';
import { GroupModalComponent } from './partials/group-modal.component';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-manage-groups',
  templateUrl: 'manage-groups.component.html'
})
export class ManageGroupsComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource();
  displayedColumns = ['Title', 'Grade', 'students_count', 'AssociatedQuestBlocks', 'Edit'];
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;
  public translations: any = {};
  public questBlocks: any = [];
  public permissions = [];
  private modalSubscription: EventEmitter<any>;
  public groupsLoading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalService: BsModalService,
    private _localeService: BsLocaleService,
    private translationService: TranslationService,
    private groupsService: GroupsService,
    private questBlocksService: QuestblocksService,
    private alertService: AlertService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
    this._localeService.use('en-gb');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue' });
  }

  ngOnInit() {
    this.groupsLoading = true;
    this.refresh();
    this.modalSubscription = this.modalService.onHide.subscribe(() => this.refresh()); // refresh on close modal
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  refresh() {
    this.groupsService.getGroups()
      .then(groups => {
        this.dataSource = new MatTableDataSource(groups);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.groupsLoading = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.groupsLoading = false;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });

    this.questBlocksService.getQuestBlocks()
      .then(questBlocks => {
        this.questBlocks = questBlocks;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openDialog(group: object, type: string) {
    const componentType = GroupModalComponent;
    const initialState: any = {
      title: 'Manage ' + this.translations.group,
      type: type,
      data: {
        individualObject: {
          Title: '',
          Grade: '',
          rumbles_quest_blocks: []
        }
      }
    };

    if (type === 'edit') {
      initialState.title = 'Edit ' + this.translations.group;
      initialState.data.individualObject = group;
    } else if (type === 'add') {
      initialState.title = 'Add ' + this.translations.group;
    }

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
