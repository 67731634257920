import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, ParamMap } from '@angular/router';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AlertService } from './alerts/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
  model: any = {};
  loading = false;
  error = null;
  success = null;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private readonly router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    //
  }

  ngOnDestroy() {
    //
  }

  resetPassword() {
    this.loading = true;
    this.authService.postResetPassword(this.model.email)
      .then(response => {
        if (typeof response.email !== 'undefined') {
          this.alertService.show(response.email, 'danger');
        } else {
          this.success = response.message;
        }
        this.loading = false;
      })
      .catch((msg) => {
        if (typeof msg.error.message !== 'undefined') {
          this.alertService.show(msg.error.message, 'danger');
        }
        this.loading = false;
      });
  }
}
