import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { OrganisationsService } from '../services/organisations.service';
import { AlertService } from '../alerts/alert.service';
import { UserService } from '../services/user.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-school-nsw-consent-pre-invitation',
  templateUrl: './school-nsw-consent-pre-invitation.component.html'
})
export class SchoolNswConsentPreInvitationComponent implements OnInit {
  public currentDate = new Date();
  public inviteQueryParams = {
    schoolId: null,
    principalName: null,
  };
  public organisation = null;
  public formModel: any = {};
  public submitting = false;

  organisationsControl = new FormControl();
  organisationsOptions: any = [];
  filteredOptions: Observable<any[]>;

  constructor(
    private route: ActivatedRoute,
    private organisationService: OrganisationsService,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.inviteQueryParams.schoolId = params.get('schoolId');
      this.inviteQueryParams.principalName = params.get('principalName');

      if (this.inviteQueryParams.schoolId !== null) {
        this.getOrganisationById();
      } else {
        this.getAllOrganisations('school');
      }
    });

    this.filteredOptions = this.organisationsControl.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.organisationsOptions.slice())
      );

    // Subscribe to form changes
    const __this = this;
    this.organisationsControl.valueChanges.subscribe(function (option) {
      if (typeof option.name === 'undefined') {
        return;
      }
      __this.organisation = option;
    });
  }

  displayFn(org?: any): string | undefined {
    return org ? org.name : undefined;
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.organisationsOptions.filter(function (option) {
      return option.name.toLowerCase().indexOf(filterValue) === 0;
    });
  }

  getAllOrganisations(type) {
    this.organisationService.getOrganisationListAsGuest(type, 'nsw')
      .then(data => {
        console.log(data);
        this.organisationsOptions = data;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  getOrganisationById() {
    this.organisationService.getOrganisationById(this.inviteQueryParams.schoolId)
      .then(data => {
        console.log(data);
        this.organisation = data;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  submitForm() {
    if (typeof this.organisation.id === 'undefined') {
      this.alertService.show('Error! No organisation has been detected. Please ensure the URL is correct, or that you have selected an organisation.', 'danger');
    }

    console.log(this.formModel);

    if (this.inviteQueryParams.principalName !== null) {
      this.formModel.invited_by = this.inviteQueryParams.principalName;
    }
    console.log(this.formModel);

    this.formModel.organisation_id = this.organisation.id;
    this.submitting = true;

    this.organisationService.inviteUserToOrganisation(this.formModel)
      .then(message => {
        this.alertService.show('Success! We have sent the invitation via email.', 'success');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.submitting = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.submitting = false;
        const alertService = this.alertService;

        if (typeof msg.error.message !== 'undefined') {
          alertService.show(msg.error.message, 'danger');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return true;
        }

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }
}
