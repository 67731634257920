import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { IndividualsService } from '../../services/individuals.service';
import { GroupsService } from '../../services/groups.service';
import { AlertService } from '../../alerts/alert.service';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-individual-modal',
    templateUrl: 'individual-modal.component.html',
})
export class IndividualModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Submit';
  data: any = {};
  type: string;
  formModel: any = {};
  groups: any = [];
  selectedGroups = [];
  form = {
    first_name: new FormControl(),
    last_name: new FormControl(),
    school_student_id: new FormControl(),
    date_of_birth: new FormControl(),
    gender: new FormControl(),
    groups: new FormControl(),
    new_group: new FormControl(),
    new_grade: new FormControl(),
    indigenous: new FormControl()
  };
  moment: any;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private groupsService: GroupsService,
    private individualsService: IndividualsService
  ) {
    this.moment = moment;
    this.moment.locale('en-AU');
  }

  ngOnInit() {
    this.mapDataToForm();
    this.createSelectedGroupsArray();
    this.refresh();
  }

  refresh() {
    const selectedGroups = this.selectedGroups;
    this.groupsService.getGroups()
      .then(groups => {
        groups.forEach(function (value, idx) {
          if (selectedGroups.includes(value.id)) {
            groups[idx].checked = true;
          } else {
            groups[idx].checked = false;
          }
        });

        this.formModel.rumbles_groups = groups;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  mapDataToForm() {
    // Make all included groups already checked
    const rumblesGroups = [];
    this.data.individualObject.rumbles_groups.forEach(function (value, idx) {
      value.checked = true;
      rumblesGroups.push(value);
    });
    this.data.individualObject.rumbles_groups = rumblesGroups;

    // Set date to format Eg 01/12/2019
    const dateValidation = this.moment(this.data.individualObject.date_of_birth, 'YYYY/MM/DD HH:mm:ss').isValid();
    let dateOfBirth = this.data.individualObject.date_of_birth;
    if (dateValidation) {
      dateOfBirth = this.moment(this.data.individualObject.date_of_birth).format('DD-MM-YYYY');
    }

    // Map all fields to data
    this.formModel.id = this.data.individualObject.id;
    this.formModel.first_name = this.data.individualObject.FirstName;
    this.formModel.last_name = this.data.individualObject.LastName;
    this.formModel.school_student_id = this.data.individualObject.school_student_id;
    this.formModel.date_of_birth = dateOfBirth;
    this.formModel.gender = this.data.individualObject.gender;
    this.formModel.is_indigenous = this.data.individualObject.is_indigenous;
    this.formModel.rumbles_groups = this.data.individualObject.rumbles_groups;
  }

  submitForm() {
    // Only include checked results
    this.formModel.rumbles_groups = this.formModel.rumbles_groups.filter(value => value.checked);

    this.individualsService.updateOrCreateIndividual(this.type, this.formModel)
      .then(message => {
        this.alertService.show('Success! This individual has been updated.', 'success');
        this.bsModalRef.hide();
        console.log(message);
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            alertService.show(val[0], 'danger');
          }
        });

        this.bsModalRef.hide();
      });

    console.log(this.formModel);
  }

  createSelectedGroupsArray() {
    const selected = [];
    this.data.individualObject.rumbles_groups.forEach(function (value) {
      selected.push(value.id);
    });
    this.selectedGroups = selected;
  }
}
