import { ActionWithPayload } from '../interfaces/action-with-payload';

export const ADD_REPORT_FILTERING = 'ADD_REPORT_FILTERING';
export const REMOVE_REPORT_FILTERING = 'REMOVE_REPORT_FILTERING';
export const RESET_REPORT_FILTERING = 'RESET_REPORT_FILTERING';
export const PUSH_REPORT_FILTERING = 'PUSH_REPORT_FILTERING';

export function reportFilteringReducer(state: Array<object>, action: ActionWithPayload) {
  switch (action.type) {
    case ADD_REPORT_FILTERING:
      state = action.payload;

      return state;

    case PUSH_REPORT_FILTERING:
      if (typeof state === 'undefined') {
        state = [];
      }

      state.push(action.payload);

      return state;

    case REMOVE_REPORT_FILTERING:
      // TODO
      return state;

    case RESET_REPORT_FILTERING:
      return [];

    default:
      return state;
  }
}
