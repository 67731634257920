import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class OrganisationsService {
  constructor(private readonly apiService: ApiService) {}

  public getOrganisationById(id): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/organisation/get/` + id + `/guest`)
      .toPromise<any>();
  }

  public getOrganisationListAsGuest(type, consent): Promise<any> {
    let url = `api/rumbles/organisations/all/guest`;

    if (type !== null) {
      url += `?type=${type}`;
    }

    if (consent !== null) {
      url += `&consent=${consent}`;
    }

    return this.apiService
      .get<any>(url)
      .toPromise<any>();
  }

  public inviteUserToOrganisation(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/organisation/invite-new-user`, data)
      .toPromise<any>();
  }

  public getInvitationOrganisationUserByToken(token): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/organisation/invitation/` + token)
      .toPromise<any>();
  }
}
