import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html'
})
export class HowToUseComponent {
  public organisationType = '';

  constructor(
    private translationService: TranslationService
  ) {
    this.organisationType = this.translationService.getUserType();
  }
}
