import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.container.html'
})
export class ReportsContainer implements OnDestroy {
  public userData: any = {
    roles: [],
    organisation: {}
  };
  private userSubscription;

  constructor(private userService: UserService, private authService: AuthService, private router: Router) {
    if (this.authService.checkLoggedIn()) {
      this.userSubscription = this.userService.getLoggedInUser()
        .subscribe((userData) => {
          this.userData = userData;
        });
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  public ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
