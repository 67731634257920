import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationExtras } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html'
})

export class EmailConfirmationComponent implements OnInit, OnDestroy {
  model: any = {};
  private sub: any;
  emailConfirmationKey: string;
  loading = false;
  error: any = '';

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private readonly router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.emailConfirmationKey = params['key'];
    });

    this.refresh();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  refresh() {
    this.loading = true;
    this.error = false;

    this.authService.postConfirmEmailByKey(this.emailConfirmationKey)
      .then(response => {
        this.loading = false;
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          queryParams: {
            success: 'You have successfully confirmed your account. Please login below.'
          },
        };

        this.router.navigate(['/auth/login'], navigationExtras);
      })
      .catch(error => {
        this.error = 'Error! Could not verify token';

        if (typeof error.error.message !== 'undefined') {
          this.error = error.error.message;
        }

        this.loading = false;
      });
  }
}
