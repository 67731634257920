import { ActionWithPayload } from '../interfaces/action-with-payload';

export const ADD_QUEST_BLOCK = 'ADD_QUEST_BLOCK';
export const REMOVE_QUEST_BLOCK = 'REMOVE_QUEST_BLOCK';
export const RESET_QUEST_BLOCK = 'RESET_QUEST_BLOCK';
export const PUSH_QUEST_BLOCK = 'PUSH_QUEST_BLOCK';
export const OVERRIDE_BULK_QUEST_BLOCK = 'OVERRIDE_BULK_QUEST_BLOCK';

export function questBlockFilteringReducer(state: Array<object>, action: ActionWithPayload) {
  switch (action.type) {
    case ADD_QUEST_BLOCK:
      state = action.payload;

      return state;

    case PUSH_QUEST_BLOCK:
      if (typeof state === 'undefined') {
        state = [];
      }

      state.push(action.payload);

      return state;

    case OVERRIDE_BULK_QUEST_BLOCK:
      if (typeof state === 'undefined') {
        state = [];
      }

      state = action.payload;

      return state;
    case REMOVE_QUEST_BLOCK:
      // TODO
      return state;

    case RESET_QUEST_BLOCK:
      return [];

    default:
      return state;
  }
}
