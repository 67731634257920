import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: 'top-menu.component.html'
})
export class TopMenuComponent {
  public translations: any = {};
  public permissions = [];

  constructor(
    private translationService: TranslationService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
  }
}
