import { ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

export class FormValidatorService {
  public force(form: NgForm, formContainer: ElementRef = null, offset: number = 100) {
    for (const control of Object.keys(form.controls)) {
      form.controls[control].markAsTouched();
      form.controls[control].markAsDirty();
    }

    if (formContainer != null) {
      const invalidElements = $(formContainer.nativeElement).find('.form-control.ng-invalid,.form-check-input.ng-invalid,.form-radio-input.ng-invalid,ep-upload.ng-invalid,my-date-picker.ng-invalid');
      let foundInvalidField = invalidElements.filter(':visible:first');

      if (foundInvalidField.length === 0) {
        foundInvalidField = invalidElements.filter(':first');
      }

      (<any>$).scrollTo(foundInvalidField, 200, {
        offset: {
          top: -(offset + 120) // offset to ensure show the label/field help within the scroll view
        }
      });
    }
  }
}
