import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { GroupsService } from '../../services/groups.service';
import { AlertService } from '../../alerts/alert.service';
import { ReportsService } from '../../services/reports.service';
import { ReportingService } from '../../services/reporting.service';

@Component({
  selector: 'app-individual-scores-modal',
  templateUrl: 'individual-scores-modal.component.html',
})
export class IndividualScoresModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Submit';
  data: any = {};
  answers: any = [];
  student: any = null;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private reportingService: ReportingService
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.refresh();
  }

  refresh() {
    this.reportingService.getQuestionAnswersByStudentAndStudentQuestBlock({
        studentId: this.data.StudentId,
        studentQuestBlockId: this.data.StudentQuestBlockId
    })
      .then(response => {
        console.log(response);
        if (typeof response.allAnswers !== 'undefined') {
          this.answers = response.allAnswers;
        }
        if (typeof response.student !== 'undefined') {
          this.student = response.student;
        }
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }
}
