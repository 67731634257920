import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HopscotchService } from 'ngx-hopscotch';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './services/user.service';
import { TranslationService } from './services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  routeSub;
  public translations: any = {};

  constructor(
    private readonly router: Router,
    private userService: UserService,
    private _hopscotchService: HopscotchService,
    private translationService: TranslationService
  ) {
    this.translations = translationService.getTranslations();
  }

  public ngOnInit(): void {
    this.routeSub = this.router.events.subscribe((evt: any) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const navigationEnd = evt as NavigationEnd;

      if (navigationEnd.url.indexOf('noScroll=true') > -1) {
        return;
      }

      $('html').animate({ scrollTop: 0 }, 'fast');
    });

    // this.initaliseWalkthrough();
  }

  public ngAfterViewInit() {
    // this._hopscotchService.init();

    // this._hopscotchService.step(0);
  }

  public ngOnDestroy() {
    this.routeSub.unsubscribe();
    // this._hopscotchService.end();
  }

  private initaliseWalkthrough(): void {
    // @todo
    // only run if logged in
    // only run if account hasnt run before.

    if (localStorage.getItem('hopscotch') || !localStorage.getItem('access_token')) {
      return;
    }

    this._hopscotchService.configure([
      {
        stepIndex: 0,
        stepDef: {
          target: '#logo',
          placement: 'right',
          content: 'Thank you for trying the Rumbles Quest demo. Click the "Next" button to begin.',
          title: 'Welcome to Rumbles Quest demo!',
          multipage: false,
          onNext: () => {
            this.router.navigate(['/quest-blocks']);
          }
        }
      },
      {
        stepIndex: 1,
        stepDef: {
          target: '#menu-quest-blocks',
          placement: 'bottom',
          content: 'Quest Blocks are used to generate a key for the game, and for reporting against.',
          title: 'Step 1: Create a Quest Block',
          multipage: false,
          onNext: () => {
            this.router.navigate(['/manage-groups']);
          },
        }
      },
      {
        stepIndex: 2,
        stepDef: {
          target: '#menu-manage-groups',
          placement: 'bottom',
          content: 'Groups may be a class, or a subset of ' + this.translations.students + '. ' +
          'These may also be created through "Bulk CSV Import" of Managing ' + this.translations.students,
          title: 'Step 2: Create a Group',
          multipage: false,
          onPrev: () => {
            this.router.navigate(['/quest-blocks']);
          },
          onNext: () => {
            this.router.navigate(['/manage-individuals']);
          },
        }
      },
      {
        stepIndex: 3,
        stepDef: {
          target: '#menu-manage-individuals',
          placement: 'bottom',
          content: this.translations.students + ' may be created individually, or through Bulk CSV Import. ' +
          'If in a Bulk CSV Import, the group will be created automatically if not already created.',
          title: 'Step 3: Create or Bulk Import ' + this.translations.students,
          multipage: false,
          onPrev: () => {
            this.router.navigate(['/manage-groups']);
          },
          onNext: () => {
            this.router.navigate(['/align-responses']);
          },
        }
      },
      {
        stepIndex: 4,
        stepDef: {
          target: '#menu-align-responses',
          placement: 'right',
          content: 'Align the responses for the Quest Block Key used. Remember to create ' + this.translations.students +
          'first so we can match up game results with them.',
          title: 'Step 4: Play the Game & Align Responses',
          multipage: false,
          onPrev: () => {
            this.router.navigate(['/manage-individuals']);
          },
          onNext: () => {
            localStorage.setItem('hopscotch', 'complete');
            this.router.navigate(['/report/group-summary-report']);
          },
        }
      },
      {
        stepIndex: 5,
        stepDef: {
          target: '#menu-group-summary-report',
          placement: 'right',
          content: 'Once you have aligned the responses for the Quest Block Key used, you can run reports ' +
          'giving useful and actionable data from a high-level to an individual level.',
          title: 'Step 5: Run Reports',
          multipage: false
        }
      }
    ]);
  }
}
