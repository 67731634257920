import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  constructor(private authService: AuthService, private readonly router: Router) {
    const homePage = window.location.pathname.indexOf('/');

    if (!authService.checkLoggedIn() && homePage === 0) {
      this.router.navigate(['/auth/login']);
    } else {
      this.router.navigate(['/training-essentials']);
    }
  }
}
