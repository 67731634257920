import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { IndividualsService } from '../../services/individuals.service';
import { AlertService } from '../../alerts/alert.service';

@Component({
    selector: 'app-individual-modal',
    templateUrl: 'unarchive-modal.component.html',
})
export class UnarchiveModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Unarchive';
  data: any = {};
  formModel: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private individualsService: IndividualsService
  ) {}

  ngOnInit() {
    this.mapDataToForm();
  }

  mapDataToForm() {
    this.formModel.id = this.data.individualObject.id;
    this.formModel.first_name = this.data.individualObject.FirstName;
    this.formModel.last_name = this.data.individualObject.LastName;
  }

  submitForm() {
    this.individualsService.unarchiveIndividual(this.formModel)
      .then(message => {
        this.alertService.show('Success! This individual has been unarchived.', 'success');
        this.bsModalRef.hide();
        console.log(message);
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });

        this.bsModalRef.hide();
      });
  }
}
