import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-out-default',
  templateUrl: './logged-out-default.container.html'
})
export class LoggedOutDefaultContainer implements OnInit, OnDestroy {

  constructor() {
    //
  }

  public ngOnInit() {
    //
  }

  public ngOnDestroy() {
    //
  }
}
