import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { colors } from '../constants/colors.constant';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { QuestBlockFilteringService } from './quest-block-filtering.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs/Rx';
import { ReportingService } from '../services/reporting.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { IndividualsService } from '../services/individuals.service';
import { AlertService } from '../alerts/alert.service';
import { PermissionsService } from '../services/permissions.service';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-featured-child-report',
  templateUrl: 'featured-child-report.component.html'
})
export class FeaturedChildReportComponent implements OnInit, OnDestroy {
  public featuredChildrenListLoading = false;
  public questBlocks = [];
  private questBlockFilteringSubscription: Subscription;
  public triggerChange = 0;
  public chosenUser: any = [];
  public plotMultipleStudentsControl = new FormControl(false);
  featuredChildControl = new FormControl();
  featuredChildOptions: any = [];
  filteredOptions: Observable<any[]>;

  public wellbeingGraph: any = {
    loading: true,
    submissionCount: 0,
    categories: [
      'Overall Wellbeing',
      'Attachment to School',
      'Self-regulation',
      'Social confidence',
      'Supportive Relationships',
    ],
    colors: [
      colors.thriving,
      colors.ontrack,
      colors.atrisk,
      colors.vulnerable,
      colors.population,
      colors.groupMedian
    ],
    series: [
      {
        data: [],
        id: 'thriving',
        name: ' ',
        type: 'column'
      },
      {
        data: [],
        id: 'ontrack',
        name: ' ',
        type: 'column'
      },
      {
        data: [],
        id: 'atrisk',
        name: ' ',
        type: 'column'
      },
      {
        data: [],
        id: 'vulnerable',
        name: ' ',
        type: 'column'
      },
      {
        data: [],
        id: 'population',
        name: 'Population Benchmark',
        type: 'spline'
      },
      // {
      //   data: [],
      //   id: 'group',
      //   name: 'Population Median',
      //   type: 'spline'
      // },
    ],
  };
  public executiveGraph = {
    loading: true,
    submissionCount: 0,
    categories: [
      'Spatial Span Working Memory',
      'Inhibitory Control',
      'Cognitive Flexibility',
      'Attention Control',
      'Focused Attention',
    ],
    colors: [
      colors.thrivingDark,
      colors.ontrackDark,
      colors.atriskDark,
      colors.vulnerableDark,
      colors.population,
      colors.groupMedian
    ],
    series: [
      {
        data: [],
        id: 'aboveexpected',
        name: ' ',
        type: 'column',
      },
      {
        data: [],
        id: 'belowexpected',
        name: ' ',
        type: 'column'
      },
      {
        data: [],
        id: 'population',
        name: 'Population Benchmark',
        type: 'spline'
      },
      // {
      //   data: [],
      //   id: 'group',
      //   name: 'Population Average',
      //   type: 'spline'
      // },
    ],
  };
  public permissions = [];
  public fullReportResponse: any;
  public translations: any = {};

  constructor(
    private store: Store<any>,
    private questBlockFilteringService: QuestBlockFilteringService,
    private individualsService: IndividualsService,
    private alertService: AlertService,
    private reportingService: ReportingService,
    private translationService: TranslationService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
    this.resetGraphData();

    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlockFilter !== 'undefined') {
        this.resetGraphData();
        this.questBlocks = val.questBlockFilter;
        this.getIndividualsByQuestBlock();
        this.refresh();
      }
    });
  }

  ngOnInit() {
    this.questBlocks = this.questBlockFilteringService.questBlocks;
    this.filteredOptions = this.featuredChildControl.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.FullName),
        map(FullName => FullName ? this._filter(FullName) : this.featuredChildOptions.slice())
      );

    // Subscribe to form changes
    const __this = this;
    this.featuredChildControl.valueChanges
      .subscribe(function (option) {
        if (typeof option.FullName === 'undefined') {
          return;
        }

        // If user is not plotting multiple students, reset the array
        if (__this.plotMultipleStudentsControl.value === false) {
          __this.chosenUser = [];
        }

        __this.chosenUser.push(option);
        __this.refresh(); // call refresh with user object
      });

    // Get Individuals by Quest Block
    this.getIndividualsByQuestBlock();
  }

  ngOnDestroy() {
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.featuredChildOptions.filter(function (option) {
      return option.FullName.toLowerCase().indexOf(filterValue) === 0;
    });
  }

  displayFn(user?: any): string | undefined {
    return user ? user.FullName : undefined;
  }

  public resetGraphData() {
    console.log('reset graph data');
    const __this = this;

    this.fullReportResponse = null;
    this.questBlocks = [];
    this.chosenUser = [];
    this.featuredChildOptions = [];

    this.wellbeingGraph = {
      loading: true,
      submissionCount: 0,
      categories: [
        'Overall Wellbeing',
        'Attachment to School',
        'Self-regulation',
        'Social confidence',
        'Supportive Relationships',
      ],
      colors: [
        colors.thriving,
        colors.ontrack,
        colors.atrisk,
        colors.vulnerable,
        colors.population,
        colors.groupMedian
      ],
      series: [
        {
          data: [],
          id: 'thriving',
          name: ' ',
          type: 'column'
        },
        {
          data: [],
          id: 'ontrack',
          name: ' ',
          type: 'column'
        },
        {
          data: [],
          id: 'atrisk',
          name: ' ',
          type: 'column'
        },
        {
          data: [],
          id: 'vulnerable',
          name: ' ',
          type: 'column'
        },
        {
          data: [],
          id: 'population',
          name: 'Population Benchmark',
          type: 'spline'
        },
        // {
        //   data: [],
        //   id: 'group',
        //   name: 'Population Median',
        //   type: 'spline'
        // },
      ],
    };

    this.executiveGraph = {
      loading: true,
      submissionCount: 0,
      categories: [
        'Spatial Span Working Memory',
        'Inhibitory Control',
        'Cognitive Flexibility',
        'Attention Control',
        'Focused Attention',
      ],
      colors: [
        colors.thrivingDark,
        colors.ontrackDark,
        colors.atriskDark,
        colors.vulnerableDark,
        colors.population,
        colors.groupMedian
      ],
      series: [
        {
          data: [],
          id: 'aboveexpected',
          name: ' ',
          type: 'column',
        },
        {
          data: [],
          id: 'belowexpected',
          name: ' ',
          type: 'column'
        },
        {
          data: [],
          id: 'population',
          name: 'Population Benchmark',
          type: 'spline'
        },
        // {
        //   data: [],
        //   id: 'group',
        //   name: 'Population Average',
        //   type: 'spline'
        // },
      ],
    };

    this.triggerChange++; // triggers child chart refresh
  }

  public getIndividualsByQuestBlock() {
    if (this.questBlocks.length === 0) {
      return; // only if quest blocks are set should it be run
    }

    this.featuredChildrenListLoading = true;

    const requestParams = [];
    this.questBlocks.forEach(function (val) {
      requestParams[val.name] = val.value;
    });

    this.individualsService.getIndividualsInQuestBlocks(requestParams)
      .then(individuals => {
        this.featuredChildOptions = individuals;
        this.triggerChange++; // triggers child chart refresh
        this.featuredChildrenListLoading = false;
      })
      .catch((msg) => {
        this.alertService.show(msg.error.message, 'danger');
        this.featuredChildrenListLoading = false;
      });
  }

  public refresh() {
    if (this.questBlocks.length === 0 || this.chosenUser.length === 0) {
      return; // only if quest blocks are set should it be run
    }

    const requestParams = [];
    requestParams['questBlocks'] = this.questBlocks.map(function(k) { return k.value; }).join(',');
    requestParams['studentIds'] = this.chosenUser.map(function(k) { return k.id; });
    requestParams['studentIds'] = Array.from(new Set(requestParams['studentIds'])); // remove dupes
    requestParams['studentIds'] = requestParams['studentIds'].join(',');

    this.reportingService.getFeaturedChildSummaryData(requestParams)
      .then(report => {
        this.fullReportResponse = report;
        this.wellbeingGraph = this.turnGroupSummaryResponseIntoGraphableData(
          report,
          'wellbeingData',
          'studentWellbeingData',
          'ScorePercentileRelativeToPopulation',
          this.wellbeingGraph
        );
        this.executiveGraph = this.turnGroupSummaryResponseIntoGraphableData(
          report,
          'executiveData',
          'studentExecutiveData',
          'ScoreAsPercentageOfMaxScore',
          this.executiveGraph
        );
        this.triggerChange++; // triggers child chart refresh
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  public turnGroupSummaryResponseIntoGraphableData(report, responseDataKey, studentResponseDataKey, studentPercentileKey, graphObject) {
    console.log(report, responseDataKey);

    // reset back to original length (removes students)
    graphObject.series = graphObject.series.slice(0, 6);

    // reset series
    for (let i = 0; i < graphObject.series.length; i++) {
      graphObject.series[i].data = [];
    }

    // loop over results to update series
    const accrualData = {
      ThrivingPercentile: [],
      OnTrackPercentile: [],
      AtRiskPercentile: [],
      VulnerablePercentile: [],
      PopulationBenchmark: [],
      BelowExpectedLevelPercentageOfGroup: [],
      AboveExpectedLevelPercentageOfGroup: [],
      GroupAverage: [], // GroupMedian, might need to calc as per old
      PopulationAverage: [], // GroupMedian, might need to calc as per old
      GroupTotalCount: 0,
    };

    const groupMedianKeys = Object.keys(report['allDataMedians']);
    for (let i = 0; i < report[responseDataKey].length; i++) {
      if (responseDataKey === 'wellbeingData') {
        // Hard code featured child as its about the score, not the group values as we're hiding labels (5/3/19 by Kate)
        accrualData['ThrivingPercentile'].push(10); // 90 - 100
        accrualData['OnTrackPercentile'].push(65); // 25 - 90
        accrualData['AtRiskPercentile'].push(15); // 10 - 25
        accrualData['VulnerablePercentile'].push(10); // 0-10
        accrualData['PopulationBenchmark'].push(50);
      } else {
        accrualData['BelowExpectedLevelPercentageOfGroup'].push(16); // 0 - 16
        accrualData['AboveExpectedLevelPercentageOfGroup'].push(84); // 17 - 100
        accrualData['PopulationBenchmark'].push(50);
      }

      accrualData['GroupTotalCount'] = report[responseDataKey][0]['GroupTotalCount'];
    }

    // Student Loop
    const studentArray = [];

    for (let i = 0; i < report[studentResponseDataKey].length; i++) { // student loop
      // Loop over each quest block key for the student
      for (const [key, value] of Object.entries(report[studentResponseDataKey][i].data)) {
        // Group by StudentQuestBlockId
        // @ts-ignore
        const groupedValue = value.reduce(function (r: any, a: any) {
          r[a.StudentQuestBlockId] = r[a.StudentQuestBlockId] || [];
          r[a.StudentQuestBlockId].push(a);
          return r;
        }, Object.create(null));

        const studentQuestBlockKeys = Object.keys(groupedValue);

        // Loop over the grouped submissions (key = StudentQuestBlockId)
        for (let j = 0; j < studentQuestBlockKeys.length; j++) {
          const studentLoop = [];
          const loop = groupedValue[studentQuestBlockKeys[j]];
          let submissionDate = '';

          // For each row for that student's quest block, prepare the line's data
          // @ts-ignore
          for (let s = 0; s < loop.length; s++) { // inner
            submissionDate = loop[s].SubmissionDate; // Set the submission date for each loop - will be the same each time as we grouped by StudentQuestBlockId which is 1 submission
            studentLoop.push(parseFloat(loop[s][studentPercentileKey]));
          }

          studentArray.push({
            studentId: report[studentResponseDataKey][i].studentId,
            studentName: report[studentResponseDataKey][i].student.student_name.FullName + ' (' + key + ', ' + formatDate(submissionDate, 'dd MMMM yyyy', 'en') + ')',
            data: studentLoop
          });
        }
      }
    }

    if (responseDataKey === 'wellbeingData') {
      graphObject.series[0].data = accrualData.ThrivingPercentile;
      graphObject.series[1].data = accrualData.OnTrackPercentile;
      graphObject.series[2].data = accrualData.AtRiskPercentile;
      graphObject.series[3].data = accrualData.VulnerablePercentile;
      graphObject.series[4].data = accrualData.PopulationBenchmark;
    } else {
      graphObject.series[0].data = accrualData.AboveExpectedLevelPercentageOfGroup;
      graphObject.series[1].data = accrualData.BelowExpectedLevelPercentageOfGroup;
      graphObject.series[2].data = accrualData.PopulationBenchmark;
    }

    graphObject.submissionCount = accrualData.GroupTotalCount;
    graphObject.loading = false;

    // Do not show group average if group total under 10 as it's skewed data
    if (report[responseDataKey][0]['GroupTotalCount'] < 10) {
      graphObject.series = graphObject.series.map(function(item) {
        if (item.id === 'group') {
          item.visible = false;
        }

        return item;
      });
    }

    // Remove doubling up of student - use filter item.id and contains 'student'
    graphObject.series = graphObject.series.filter(function(item) {
      return !item.id.includes('student');
    });

    // If array isn't empty, push to its own line
    studentArray.forEach(function (item) {
      graphObject.series.push({
        data: item.data,
        id: 'student' + item.studentId,
        name: item.studentName,
        type: 'spline',
        color: '#000'
      });
    });

    return graphObject;
  }

  public print() {
    window.print();
  }
}
