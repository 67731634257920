import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { IndividualsService } from '../services/individuals.service';
import { AlertService } from '../alerts/alert.service';
import { IndividualModalComponent } from './partials/individual-modal.component';
import { ArchiveModalComponent } from './partials/archive-modal.component';
import { IndividualBulkImportComponent } from './partials/individual-bulk-import.component';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-individual-component',
  templateUrl: 'manage-individual.component.html'
})
export class ManageIndividualComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource();
  displayedColumns = ['FirstName', 'gender', 'school_student_id', 'date_of_birth', 'rumbles_student_answers_count', 'rumbles_groups', 'rumbles_quest_blocks', 'updated_at', 'Edit', 'Archive'];
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;
  public translations: any = {};
  public individualTags: any[];
  public bulkEmailsList = '';
  public addTagsArr = {
    name: '',
    value: ''
  };
  public individualsLoading = false;
  public permissions = [];
  private modalSubscription: EventEmitter<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalService: BsModalService,
    private _localeService: BsLocaleService,
    private translationService: TranslationService,
    private individualsService: IndividualsService,
    private alertService: AlertService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
    this._localeService.use('en-gb');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue' });
  }

  ngOnInit() {
    this.individualsLoading = true;
    this.refresh();
    this.modalSubscription = this.modalService.onHide.subscribe(() => this.refresh()); // refresh on close modal
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  refresh() {
    this.individualsService.getIndividuals()
      .then(individuals => {
        this.dataSource = new MatTableDataSource(individuals);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.individualsLoading = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.individualsLoading = false;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openBulkCSV() {
    const componentType = IndividualBulkImportComponent;
    const initialState: any = {
      title: 'Bulk CSV Import'
    };

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openDialog(individualObject: any, type: string) {
    const componentType = IndividualModalComponent;
    const initialState: any = {
      title: 'Manage ' + this.translations.student,
      type: type,
      data: {
        individualObject: {
          first_name: '',
          last_name: '',
          school_student_id: '',
          date_of_birth: '',
          gender: '',
          is_indigenous: '',
          rumbles_groups: []
        }
      }
    };
    const fullName = individualObject.first_name && individualObject.last_name ? individualObject.first_name + ' ' + individualObject.last_name : '';

    if (type === 'edit') {
      initialState.title = 'Edit ' + this.translations.student + ' ' + fullName;
      initialState.data.individualObject = individualObject;
    } else if (type === 'add') {
      initialState.title = 'Add ' + this.translations.student;
    }

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openArchiveDialog(individualObject: any) {
    const componentType = ArchiveModalComponent;
    const initialState: any = {
      title: 'Archive ' + this.translations.student,
      data: {
        individualObject: individualObject
      }
    };

    initialState.data.individualObject = individualObject;

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public validateEmail = (email = null) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
}
