// Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

// NGRX Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { reportFilteringReducer } from './reducers/report-filtering';
import { participantTagsReducer } from './reducers/participant-tags-reducer';
import { questBlockFilteringReducer } from './reducers/quest-block-filtering-reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

// Import bugsnag-js and bugsnag-angular
import BugsnagErrorHandler from 'bugsnag-angular';
import bugsnag from 'bugsnag-js';

// configure Bugsnag ASAP, before any other imports
const bugsnagClient = bugsnag('380d5241c0809ab4b90f39c1f3f45424');

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}

// routing
import { AppRoutingModule } from './app-routing.module';

// containers
import { AuthContainer } from './containers/auth.container';
import { LoggedOutDefaultContainer } from './containers/logged-out-default.container';
import { DefaultContainer } from './containers/default.container';
import { DefaultNoSidebarContainer } from './containers/default-no-sidebar.container';
import { ReportsContainer } from './containers/reports.container';

// interceptor
import { TokenInterceptor } from './interceptors/token-interceptor';

// components
// Base
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header.component';
import { TopMenuComponent } from './layout/top-menu.component';
import { FooterComponent } from './footer.component';
import { SideMenuComponent } from './layout/side-menu.component';

// Auth
import { LoginComponent } from './login.component';
import { RegisterComponent } from './register.component';
import { EmailConfirmationComponent } from './auth/email-confirmation.component';
import { ResetPasswordComponent } from './reset-password.component';

// Injected Service Components
import { AlertComponent } from './alerts/alert.component';
import { LoaderComponent } from './loader/loader.component';

// Reports
import { ReportFilteringComponent } from './reports/report-filtering.component';
import { QuestBlockFilteringComponent } from './reports/quest-block-filtering.component';
import { AssetsReportComponent } from './reports/assets-report.component';
import { ChildScoresReportComponent } from './reports/child-scores-report.component';
import { FeaturedChildReportComponent } from './reports/featured-child-report.component';
import { GroupSummaryReportComponent } from './reports/group-summary-report.component';

// Administration
import { ManageIndividualComponent } from './administration/manage-individual.component';
import { ManageIndividualArchivedComponent } from './administration/manage-individual-archived.component';
import { AlignResponsesComponent } from './administration/align-responses.component';
import { AlignResponsesQuestBlockComponent } from './administration/align-responses-quest-block.component';
import { GetRumblesComponent } from './administration/get-rumbles.component';
import { ManageQuestBlocksComponent } from './administration/manage-quest-blocks.component';
import { QuestBlockModalComponent } from './administration/partials/quest-block-modal.component';
import { AlignResponsesEditModalComponent } from './administration/partials/align-responses-edit-modal.component';
import { GroupModalComponent } from './administration/partials/group-modal.component';
import { IndividualModalComponent } from './administration/partials/individual-modal.component';
import { ArchiveModalComponent } from './administration/partials/archive-modal.component';
import { UnarchiveModalComponent } from './administration/partials/unarchive-modal.component';
import { IndividualBulkImportComponent } from './administration/partials/individual-bulk-import.component';
import { ManageAccountComponent } from './administration/manage-account.component';
import { ManageGroupsComponent } from './administration/manage-groups.component';
import { AddSecondaryUserModalComponent } from './administration/partials/add-secondary-user-modal.component';
import { EditSecondaryUserModalComponent } from './administration/partials/edit-secondary-user-modal.component';
import { DeleteSecondaryUserModalComponent } from './administration/partials/delete-secondary-user-modal.component';
import { IndividualScoresModalComponent } from './reports/partials/individual-scores-modal.component';

// Pages
import { SnapshotComponent } from './snapshot.component';
import { DashboardComponent } from './dashboard.component';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from './notfound.component';
import { HowToUseComponent } from './static/how-to-use.component';
import { SchoolConsentPreInvitationComponent } from './static/school-consent-pre-invitation.component';
import { SchoolQldCathedCfcConsentPreInvitationComponent } from './static/school-qld-cathed-cfc-consent-pre-invitation.component';
import { SchoolQldCathedNonCfcConsentPreInvitationComponent } from './static/school-qld-cathed-non-cfc-consent-pre-invitation.component';
import { SchoolNswConsentPreInvitationComponent } from './static/school-nsw-consent-pre-invitation.component';
import { SchoolConsentConfirmRegistrationComponent } from './static/school-consent-confirm-registration.component';
import { SchoolTasCreateUserFromInvitationComponent } from './static/school-tas-create-user-from-invitation.component';
import { TrainingEssentialsComponent } from './static/training-essentials.component';
import { SupportComponent } from './static/support.component';
import { QuestionsResponseRankingBarComponent } from './charts/questions-response-ranking-bar-chart.component';
import { LowMedHighBandPieChartComponent } from './charts/low-med-high-band-pie-chart.component';
import { LowMedHighBandBarChartComponent } from './charts/low-med-high-band-bar-chart.component';

// services
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { AlignResponsesService } from './services/align-responses.service';
import { ReportsService } from './services/reports.service';
import { ReportingService } from './services/reporting.service';
import { OrganisationsService } from './services/organisations.service';
import { UserService } from './services/user.service';
import { IndividualsService } from './services/individuals.service';
import { QuestblocksService } from './services/questblocks.service';
import { GroupsService } from './services/groups.service';
import { LoaderService } from './loader/loader.service';
import { ReportFilteringService } from './reports/report-filtering.service';
import { QuestBlockFilteringService } from './reports/quest-block-filtering.service';
import { TranslationService } from './services/translation.service';
import { PermissionsService } from './services/permissions.service';
import { AlertService } from './alerts/alert.service';

// Utils
import { NewlinePipe } from './utils/pipes/newline.pipe';
import { FormValidatorService } from './utils/form-validator.service';
import { FileValueAccessorDirective } from './directives/FileValueAccessorDirective';

// third-party
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as seriesLabel from 'highcharts/modules/series-label.js';

import { AlertModule } from 'ngx-bootstrap/alert';
import { JwtModule } from '@auth0/angular-jwt';
import 'jquery.scrollto';
import { MomentModule } from 'angular2-moment';
import { InlineSVGModule } from 'ng-inline-svg';
import { PapaParseModule } from 'ngx-papaparse';
import { NgxHopscotchModule } from 'ngx-hopscotch';

// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatFormFieldModule, MatTableModule, MatPaginatorModule, MatSortModule, MatInputModule, MatSelectModule,
  MatOptionModule, MatDialogModule, MatButtonModule, MatBadgeModule, MatCheckboxModule, MatProgressBarModule,
  MatAutocompleteModule
} from '@angular/material';

// Define EN-GB for date picker
defineLocale('en-gb', enGbLocale);

/**
 * NGRX Store Setup
 */
export const reducers: ActionReducerMap<any> = {
  addedTagsArray: reportFilteringReducer,
  participantTags: participantTagsReducer,
  questBlockFilter: questBlockFilteringReducer
};

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [debug];

/**
 * NG Module
 */
@NgModule({
  declarations: [
    // ReportProcessComponent,
    AppComponent,
    FileValueAccessorDirective,
    AuthContainer,
    DefaultContainer,
    DefaultNoSidebarContainer,
    LoggedOutDefaultContainer,
    ReportsContainer,
    TopMenuComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    SideMenuComponent,
    RegisterComponent,
    EmailConfirmationComponent,
    LoginComponent,
    ResetPasswordComponent,
    HomeComponent,
    GetRumblesComponent,
    ManageQuestBlocksComponent,
    QuestBlockModalComponent,
    AddSecondaryUserModalComponent,
    EditSecondaryUserModalComponent,
    DeleteSecondaryUserModalComponent,
    IndividualScoresModalComponent,
    GroupModalComponent,
    AlignResponsesEditModalComponent,
    IndividualModalComponent,
    ArchiveModalComponent,
    UnarchiveModalComponent,
    IndividualBulkImportComponent,
    ManageAccountComponent,
    ManageGroupsComponent,
    AlignResponsesComponent,
    AlignResponsesQuestBlockComponent,
    SnapshotComponent,
    HowToUseComponent,
    SchoolConsentPreInvitationComponent,
    SchoolNswConsentPreInvitationComponent,
    SchoolQldCathedCfcConsentPreInvitationComponent,
    SchoolQldCathedNonCfcConsentPreInvitationComponent,
    SchoolConsentConfirmRegistrationComponent,
    SchoolTasCreateUserFromInvitationComponent,
    TrainingEssentialsComponent,
    ManageIndividualComponent,
    ManageIndividualArchivedComponent,
    SupportComponent,
    ReportFilteringComponent,
    QuestBlockFilteringComponent,
    QuestionsResponseRankingBarComponent,
    LowMedHighBandPieChartComponent,
    LowMedHighBandBarChartComponent,
    NewlinePipe,
    LoaderComponent,
    AlertComponent,
    AssetsReportComponent,
    ChildScoresReportComponent,
    FeaturedChildReportComponent,
    GroupSummaryReportComponent,
    DashboardComponent,
  ],
  entryComponents: [
    QuestBlockModalComponent,
    AddSecondaryUserModalComponent,
    EditSecondaryUserModalComponent,
    DeleteSecondaryUserModalComponent,
    IndividualScoresModalComponent,
    GroupModalComponent,
    AlignResponsesEditModalComponent,
    IndividualModalComponent,
    ArchiveModalComponent,
    UnarchiveModalComponent,
    IndividualBulkImportComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PapaParseModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    MatDialogModule,
    MatBadgeModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ChartModule,
    AlertModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument(<any>{
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    MomentModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'realwellauth.local',
          'account.realwell.org.au',
          'staging.account.realwell.org.au',
          'dev.account.realwell.org.au',
        ]
      }
    }),
    InlineSVGModule,
    NgxHopscotchModule.forRoot({
      id: 'tour',
      showPrevButton: true
    })
  ],
  exports: [
    LoaderComponent,
    ReportFilteringComponent,
  ],
  providers: [
    FormValidatorService,
    ApiService,
    AlertService,
    AlignResponsesService,
    ReportsService,
    ReportingService,
    IndividualsService,
    GroupsService,
    QuestblocksService,
    QuestBlockFilteringService,
    UserService,
    AuthService,
    LoaderService,
    TranslationService,
    PermissionsService,
    ReportFilteringService,
    OrganisationsService,
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [ more, seriesLabel ]
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
