import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TranslationService } from '../services/translation.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AlertService } from '../alerts/alert.service';
import { BsModalService } from 'ngx-bootstrap';
import { QuestblocksService } from '../services/questblocks.service';
import { AlignResponsesService } from '../services/align-responses.service';

@Component({
  selector: 'app-align-responses-quest-block',
  templateUrl: 'align-responses-quest-block.component.html'
})
export class AlignResponsesQuestBlockComponent implements OnInit {
  dataSource = new MatTableDataSource();
  displayedColumns = ['Title', 'QuestBlockKey', 'align_responses_aligned_count', 'align_responses_unaligned_count', 'View'];
  public translations: any = {};
  loading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private translationService: TranslationService,
    private alignResponsesService: AlignResponsesService,
    private alertService: AlertService
  ) {
    this.translations = translationService.getTranslations();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.alignResponsesService.getQuestBlocksWithAlignments()
      .then(questBlocks => {
        this.dataSource = new MatTableDataSource(questBlocks);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.loading = false;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
