import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class AlignResponsesService {
  constructor(private readonly apiService: ApiService) {}

  public getQuestBlocksWithAlignments(): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/align-responses/all`)
      .toPromise<any>();
  }

  public getSubmissionsByQuestBlock(questBlockKey): Promise<any> {
    return this.apiService
      .get<any>(
        `api/rumbles/align-responses/get-by-questblock`,
        {
          params: {
            questBlockKey: questBlockKey
          }
        }
      )
      .toPromise<any>();
  }

  public confirmAllUnaligned(questBlockKey): Promise<any> {
    return this.apiService
      .post<any>(
        `api/rumbles/align-responses/confirm-by-questblock`,
        {
          params: {
            questBlockKey: questBlockKey
          }
        }
      )
      .toPromise<any>();
  }

  public confirmIndividualUnaligned(questBlockKey, submissionId): Promise<any> {
    return this.apiService
      .post<any>(
        `api/rumbles/align-responses/confirm-by-submission-id`,
        {
          params: {
            questBlockKey: questBlockKey,
            submissionId: submissionId
          }
        }
      )
      .toPromise<any>();
  }

  public updateResponse(data): Promise<any> {
    return this.apiService
      .post<any>(
        `api/rumbles/align-responses/update-response`,
        data
      )
      .toPromise<any>();
  }
}
