import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, ParamMap } from '@angular/router';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AlertService } from './alerts/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
  private user;
  model: any = {};
  loading = false;
  error = null;
  success = null;
  routeParam;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private readonly router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    // reset login status
    this.authService.logout();

    this.routeParam = this.route.queryParams.subscribe(params => {
      if (typeof params.error !== 'undefined') {
        this.alertService.show(params.error, 'danger');
      }

      if (typeof params.success !== 'undefined') {
        this.alertService.show(params.success, 'success');
      }
    });
  }

  ngOnDestroy() {
    if (this.user) {
      this.user.unsubscribe();
    }
  }

  login() {
    this.loading = true;
    this.authService.getAccessToken(this.model.email, this.model.password)
      .then(response => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);

        const userSub = this.userService.getLoggedInUser();
        const userProfile = localStorage.getItem('user_profile');
        this.user = userSub
          .subscribe((user) => {
            if (userProfile === null || userProfile === 'undefined') {
              localStorage.setItem('user_profile', JSON.stringify(user));
            }
            this.router.navigate(['/']);
          }, (msg) => {
            if (typeof msg.error.message !== 'undefined') {
              this.alertService.show(msg.error.message, 'danger');
            }
            this.authService.logout();
            this.loading = false;
          });
      })
      .catch((msg) => {
        if (typeof msg.error.message !== 'undefined') {
          this.alertService.show(msg.error.message, 'danger');
        }
        this.loading = false;
      });
  }
}
