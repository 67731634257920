import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';
import { AlertState } from './alert';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
  show = false;
  type: string;
  message: string;

  private subscription: Subscription;

  constructor (private alertService: AlertService) {
    //
  }

  ngOnInit() {
    this.subscription = this.alertService.alertState
      .subscribe((state: AlertState) => {
        this.show = state.show;
        this.message = state.message;
        this.type = state.type;
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeAlert() {
    this.alertService.hide();
  }
}
