export const translations = {
  school: {
    student: 'Child',
    students: 'Children',
    group: 'Group',
    groups: 'Groups',
    grade: 'Grade',
    grades: 'Grades',
    subGroup: 'Class',
    subGroups: 'Classes',
    schoolStudentId: 'School Student ID'
  },
  agency: {
    student: 'Individual',
    students: 'Individuals',
    group: 'Group',
    groups: 'Groups',
    grade: 'Group',
    grades: 'Groups',
    subGroup: 'Sub-group',
    subGroups: 'Sub-groups',
    schoolStudentId: 'Individual ID'
  }
};
