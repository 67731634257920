import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class GroupsService {
  constructor(private readonly apiService: ApiService) {}

  public getGroups(): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/groups/all`)
      .toPromise<any>();
  }

  public updateOrCreateGroup(type, data): Promise<any> {
    let url;

    if (type === 'add') {
      url = `api/rumbles/groups/create`;
    } else if (type === 'edit') {
      url = `api/rumbles/groups/update`;
    }

    return this.apiService
      .post<any>(url, data)
      .toPromise<any>();
  }
}
