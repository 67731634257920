import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class ReportingService {
  constructor(private readonly apiService: ApiService) {}

  public getGroupSummaryData(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/group-summary/all` + requestParams
      )
      .toPromise<any>();
  }

  public getFeaturedChildSummaryData(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/featured-child-summary/all`,
      {
        params: requestParams
      })
      .toPromise<any>();
  }

  public getChildScores(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/child-scores/all`,
      {
        params: requestParams
      })
    .toPromise<any>();
  }

  public getAssetsReport(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/assets-report/all`,
      {
        params: requestParams
      })
      .toPromise<any>();
  }

  public getStudentsByQuestionId(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/assets-report/students-by-question`,
      {
        params: requestParams
      })
      .toPromise<any>();
  }

  public getQuestionAnswersByStudentAndStudentQuestBlock(requestParams): Promise<any> {
    return this.apiService.get<any>(
      `api/rumbles/reports/assets-report/question-answers-by-student-and-student-quest-block`,
      {
        params: requestParams
      })
      .toPromise<any>();
  }
}
