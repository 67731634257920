import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-training-essentials',
  templateUrl: './training-essentials.component.html'
})
export class TrainingEssentialsComponent {

}
