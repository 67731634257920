import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GroupModalComponent } from './partials/group-modal.component';
import { AddSecondaryUserModalComponent } from './partials/add-secondary-user-modal.component';
import { EditSecondaryUserModalComponent } from './partials/edit-secondary-user-modal.component';
import { DeleteSecondaryUserModalComponent } from './partials/delete-secondary-user-modal.component';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AlertService } from '../alerts/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GroupsService } from '../services/groups.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslationService } from '../services/translation.service';
import { FormControl } from '@angular/forms';
import { UserService } from '../services/user.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: 'manage-account.component.html'
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;
  formModel: any = {
    organisation: {}
  };
  userData: any = {};
  public permissions = [];
  private modalSubscription: EventEmitter<any>;

  constructor(
    private modalService: BsModalService,
    private _localeService: BsLocaleService,
    private translationService: TranslationService,
    private userService: UserService,
    private alertService: AlertService,
    private permissionService: PermissionsService
  ) {
    this._localeService.use('en-gb');
    this.permissions = this.permissionService.getPermissions();
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue' });
  }

  ngOnInit() {
    this.refresh('both');
    this.modalSubscription = this.modalService.onHide.subscribe(() => this.refresh('both')); // refresh on close modal
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  refresh(type) {
    this.userService.getCurrentUser()
      .then(user => {
        this.userData = user;
        this.mapDataToForm(type);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  openDialog(user: object, type: string) {
    const componentType = type === 'add' ? AddSecondaryUserModalComponent : EditSecondaryUserModalComponent;
    const initialState: any = {
      title: 'Add Secondary User',
      type: type,
      data: {
        individualObject: {
          Title: '',
          Grade: '',
          rumbles_quest_blocks: []
        }
      }
    };

    if (type === 'edit') {
      initialState.title = 'Edit Secondary User';
      initialState.data.individualObject = user;
    }

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openDialogToConfirmSecondaryUserRemoval(user: object) {
    const componentType = DeleteSecondaryUserModalComponent;
    const initialState: any = {
      title: 'Delete Secondary User',
      data: {
        individualObject: user,
      }
    };

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  mapDataToForm(type) {
    // Map all fields to data
    if (type === 'user' || type === 'both') {
      this.formModel.id = this.userData.id;
      this.formModel.first_name = this.userData.first_name;
      this.formModel.last_name = this.userData.last_name;
      this.formModel.email = this.userData.email;
      this.formModel.phone = this.userData.phone;
      this.formModel.newpassword = this.userData.newpassword;
    }

    if (type === 'organisation' || type === 'both') {
      this.formModel.organisation = {};
      this.formModel.organisation.id = this.userData.organisation.id;
      this.formModel.organisation.name = this.userData.organisation.name;
      this.formModel.organisation.type = this.userData.organisation.type;
      this.formModel.organisation.school_id = this.userData.organisation.school_id;
      this.formModel.organisation.initials = this.userData.organisation.initials;
      this.formModel.organisation.gender = this.userData.organisation.gender;
      this.formModel.organisation.school_type = this.userData.organisation.school_type;
      this.formModel.organisation.grade_min = this.userData.organisation.grade_min;
      this.formModel.organisation.grade_max = this.userData.organisation.grade_max;
      this.formModel.organisation.phone = this.userData.organisation.phone;
      this.formModel.organisation.street = this.userData.organisation.street;
      this.formModel.organisation.suburb = this.userData.organisation.suburb;
      this.formModel.organisation.state = this.userData.organisation.state;
      this.formModel.organisation.postcode = this.userData.organisation.postcode;
    }
  }

  submitUserForm() {
    this.userService.updateUser(this.formModel)
      .then(message => {
        this.alertService.show('Success! This individual has been updated.', 'success');
        this.refresh('user');
        console.log(message);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  submitOrganisationForm() {
    this.userService.updateOrganisation(this.formModel.organisation)
      .then(message => {
        this.alertService.show('Success! This organisation has been updated.', 'success');
        this.refresh('organisation');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        console.log(message);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }
}
