import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-get-rumbles',
  templateUrl: 'get-rumbles.component.html'
})
export class GetRumblesComponent {

}
