import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class QuestblocksService {
  constructor(private readonly apiService: ApiService) {}

  public getQuestBlocks(): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/quest-blocks/all`)
      .toPromise<any>();
  }

  public updateOrCreateQuestBlock(type, data): Promise<any> {
    let url;

    if (type === 'add') {
      url = `api/rumbles/quest-blocks/create`;
    } else if (type === 'edit') {
      url = `api/rumbles/quest-blocks/update`;
    }

    return this.apiService
      .post<any>(url, data)
      .toPromise<any>();
  }
}
