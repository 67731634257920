// @ts-ignore
import { Component, OnDestroy, OnInit } from '@angular/core';
import { colors } from '../constants/colors.constant';
import { QuestBlockFilteringService } from './quest-block-filtering.service';
// @ts-ignore
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Rx';
import { ReportingService } from '../services/reporting.service';
import { AlertService } from '../alerts/alert.service';
// @ts-ignore
import { FormControl } from '@angular/forms';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-group-summary-report',
  templateUrl: 'group-summary-report.component.html'
})
export class GroupSummaryReportComponent implements OnInit, OnDestroy {
  public questBlocks = [];
  private questBlockFilteringSubscription: Subscription;
  public triggerChange = 0;
  public bothFilter = new FormControl();
  public wellbeingFilter = new FormControl();
  public executiveFilter = new FormControl();
  public filterValues = {
    wellbeing: '',
    executive: '',
    both: ''
  };
  public allFilteredValues = [];
  public wellbeingGraph: any = {
    loading: true,
    submissionCount: 0,
    categories: [
      'Overall Wellbeing',
      'Attachment to School',
      'Self-regulation',
      'Social confidence',
      'Supportive Relationships',
    ],
    colors: [
      colors.thriving,
      colors.ontrack,
      colors.atrisk,
      colors.vulnerable,
      colors.population,
      colors.groupMedian,
    ],
    series: [
      {
        data: [],
        id: 'thriving',
        name: '% of Group in High Range',
        type: 'column',
      },
      {
        data: [],
        id: 'ontrack',
        name: '% of Group in On Track Range',
        type: 'column'
      },
      {
        data: [],
        id: 'atrisk',
        name: '% of Group in Borderline range',
        type: 'column'
      },
      {
        data: [],
        id: 'vulnerable',
        name: '% of Group in Vulnerable Range',
        type: 'column'
      },
      {
        data: [],
        id: 'population',
        name: 'Population Benchmark',
        type: 'spline'
      },
      {
        data: [],
        id: 'group',
        name: 'Group Median',
        type: 'spline'
      },
    ],
  };

  public executiveGraph = {
    loading: true,
    submissionCount: 0,
    categories: [
      'Spatial Span Working Memory',
      'Inhibitory Control',
      'Cognitive Flexibility',
      'Attention Control',
      'Focused Attention',
    ],
    colors: [
      colors.thrivingDark,
      colors.ontrackDark,
      colors.atriskDark,
      colors.vulnerableDark,
      colors.population,
      colors.groupMedian,
    ],
    series: [
      {
        data: [],
        id: 'aboveexpected',
        name: 'At or Above Expected Level',
        type: 'column',
      },
      {
        data: [],
        id: 'belowexpected',
        name: 'Below Expected Level',
        type: 'column'
      },
      {
        data: [],
        id: 'population',
        name: 'Population Benchmark',
        type: 'spline'
      },
      {
        data: [],
        id: 'group',
        name: 'Group Average',
        type: 'spline'
      },
    ],
  };
  public wellbeingGraphBackup: any = {};
  public executiveGraphBackup: any = {};
  public permissions = [];

  constructor(
    private store: Store<any>,
    private questBlockFilteringService: QuestBlockFilteringService,
    private alertService: AlertService,
    private reportingService: ReportingService,
    private permissionService: PermissionsService
  ) {
    this.permissions = this.permissionService.getPermissions();

    // Backup initial graph data for restore on each refresh
    this.wellbeingGraphBackup = JSON.parse(JSON.stringify(this.wellbeingGraph)); // deep copy
    this.executiveGraphBackup = JSON.parse(JSON.stringify(this.executiveGraph)); // deep copy

    // Subscribe to store and store in public property
    // @ts-ignore
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlockFilter !== 'undefined') {
        this.questBlocks = val.questBlockFilter;
        this.refresh(); // refresh graph on change of quest block
      }
    });

    this.wellbeingFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues.wellbeing = value;
        this.refresh();
      });

    this.executiveFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues.executive = value;
        this.refresh();
      });

    this.bothFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues.both = value;
        this.refresh();
      });
  }

  ngOnInit() {
    this.questBlocks = this.questBlockFilteringService.questBlocks;
    this.refresh(); // init load
  }

  ngOnDestroy() {
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }

  public refresh() {
    if (this.questBlocks.length === 0) {
      return; // only if quest blocks are set should it be run
    }

    // Restore fresh data
    if (Object.keys(this.wellbeingGraphBackup).length > 0) {
      this.wellbeingGraph = JSON.parse(JSON.stringify(this.wellbeingGraphBackup)); // deep copy restore
      this.executiveGraph = JSON.parse(JSON.stringify(this.executiveGraphBackup)); // deep copy restore
    }

    let requestParams: any = this.questBlocks.map(function (val) {
      return 'questBlocks[]=' + val.value;
    });

    // requestParams.push('wellbeingFilter=' + this.filterValues.wellbeing);
    // requestParams.push('executiveFilter=' + this.filterValues.executive);
    // Temporary set to use both by Kate request 5/3/19
    requestParams.push('wellbeingFilter=' + this.filterValues.both);
    requestParams.push('executiveFilter=' + this.filterValues.both);
    requestParams = '?' + requestParams.join('&');

    if (this.filterValues.both !== '') {
      this.allFilteredValues.push(this.filterValues.both); // Push current value to filtered values;
      this.allFilteredValues = Array.from(new Set(this.allFilteredValues)); // remove dupes
    }

    // console.log(requestParams);
    this.reportingService.getGroupSummaryData(requestParams)
      .then(report => {
        this.wellbeingGraph = this.turnGroupSummaryResponseIntoGraphableData(report, 'wellbeingData', this.wellbeingGraph, 'Median');
        console.log(this.wellbeingGraph);
        this.executiveGraph = this.turnGroupSummaryResponseIntoGraphableData(report, 'executiveData', this.executiveGraph, 'Average');
        this.triggerChange++; // triggers child chart refresh
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  public turnGroupSummaryResponseIntoGraphableData(report, responseDataKey, graphObject, averageType) {
    // reset series
    for (let i = 0; i < graphObject.series.length; i++) {
      graphObject.series[i].data = [];
    }

    // loop over results to update series
    const accrualData = {
      ThrivingPercentile: [],
      OnTrackPercentile: [],
      AtRiskPercentile: [],
      VulnerablePercentile: [],
      BelowExpectedLevelPercentageOfGroup: [],
      AboveExpectedLevelPercentageOfGroup: [],
      PopulationBenchmark: [],
      GroupAverage: [], // GroupMedian, might need to calc as per old
      GroupTotalCount: 0,
    };

    const groupMedianKeys = Object.keys(report['groupMedians']);
    for (let i = 0; i < report[responseDataKey].length; i++) {

      if (responseDataKey === 'wellbeingData') {
        accrualData['ThrivingPercentile'].push(parseFloat(report[responseDataKey][i]['ThrivingPercentile']));
        accrualData['OnTrackPercentile'].push(parseFloat(report[responseDataKey][i]['OnTrackPercentile']));
        accrualData['AtRiskPercentile'].push(parseFloat(report[responseDataKey][i]['AtRiskPercentile']));
        accrualData['VulnerablePercentile'].push(parseFloat(report[responseDataKey][i]['VulnerablePercentile']));
        accrualData['PopulationBenchmark'].push(50);
        accrualData['GroupAverage'].push(report['groupMedians'][groupMedianKeys[i]]);
      } else {
        accrualData['BelowExpectedLevelPercentageOfGroup'].push(
          parseFloat(report[responseDataKey][i]['BelowExpectedLevelPercentageOfGroup'])
        );
        accrualData['AboveExpectedLevelPercentageOfGroup'].push(
          parseFloat(report[responseDataKey][i]['AboveExpectedLevelPercentageOfGroup'])
        );
        accrualData['PopulationBenchmark'].push(50);
        accrualData['GroupAverage'].push(parseFloat(report[responseDataKey][i]['GroupMean']));
      }

      accrualData['GroupTotalCount'] = report[responseDataKey][0]['GroupTotalCount'];
    }

    if (responseDataKey === 'wellbeingData') {
      graphObject.series[0].data = accrualData.ThrivingPercentile;
      graphObject.series[1].data = accrualData.OnTrackPercentile;
      graphObject.series[2].data = accrualData.AtRiskPercentile;
      graphObject.series[3].data = accrualData.VulnerablePercentile;
      graphObject.series[4].data = accrualData.PopulationBenchmark;
      graphObject.series[5].data = accrualData.GroupAverage;
    } else {
      graphObject.series[0].data = accrualData.AboveExpectedLevelPercentageOfGroup;
      graphObject.series[1].data = accrualData.BelowExpectedLevelPercentageOfGroup;
      graphObject.series[2].data = accrualData.PopulationBenchmark;
      graphObject.series[3].data = accrualData.GroupAverage;
    }

    graphObject.submissionCount = accrualData.GroupTotalCount;
    graphObject.loading = false;

    // Handle filtered data
    if (
      typeof report[responseDataKey + 'Filtered'] !== 'undefined' &&
      report[responseDataKey + 'Filtered'].filterData !== null
    ) {
      Object.keys(report[responseDataKey + 'Filtered'].filterData).forEach(function (key) {
        const item = report[responseDataKey + 'Filtered'].filterData;
        const filterName = report[responseDataKey + 'Filtered'].filterName;
        const filterNameLookup = {
          'Gender': key.replace(/^./, str => str.toUpperCase()),
          'Grade': 'Grade ' + key,
          'is_indigenous_readable': 'Indigenous: ' + key,
          'StudentGroups': `'${key}' Group`
        };

        graphObject.series.push({
          'data': Object.keys(item[key]).map(itm => item[key][itm]),
          'id': key,
          'name': filterNameLookup[filterName] + ' ' + averageType,
          'type': 'spline',
          'color': '#000'
        });
      });
    }

    return graphObject;
  }

  public randomColor() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    return color;
  }
}
