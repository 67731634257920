import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { IndividualsService } from '../../services/individuals.service';
import { GroupsService } from '../../services/groups.service';
import { AlertService } from '../../alerts/alert.service';
import { QuestblocksService } from '../../services/questblocks.service';

@Component({
  selector: 'app-group-modal',
  templateUrl: 'group-modal.component.html',
})
export class GroupModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Submit';
  data: any = {};
  type: string;
  formModel: any = {};
  questBlocks: any = [];
  selectedQuestBlocks = [];
  form = {
    first_name: new FormControl(),
    last_name: new FormControl(),
    school_student_id: new FormControl(),
    date_of_birth: new FormControl(),
    gender: new FormControl(),
    groups: new FormControl(),
    indigenous: new FormControl()
  };

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private questBlocksService: QuestblocksService,
    private groupsService: GroupsService
  ) {}

  ngOnInit() {
    this.mapDataToForm();
    this.createSelectedQuestBlocksArray();
    this.refresh();
  }

  refresh() {
    const selectedQuestBlocks = this.selectedQuestBlocks;
    this.questBlocksService.getQuestBlocks()
      .then(questBlocks => {
        console.log(selectedQuestBlocks);
        questBlocks.forEach(function (value, idx) {
          if (selectedQuestBlocks.includes(value.QuestBlockId)) {
            questBlocks[idx].checked = true;
          } else {
            questBlocks[idx].checked = false;
          }
        });

        this.formModel.rumbles_quest_blocks = questBlocks;
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });
      });
  }

  mapDataToForm() {
    // Make all included groups already checked
    const rumblesQuestBlocks = [];
    this.data.individualObject.rumbles_quest_blocks.forEach(function (value, idx) {
      value.checked = true;
      rumblesQuestBlocks.push(value);
    });
    this.data.individualObject.rumbles_quest_blocks = rumblesQuestBlocks;

    const currDate = new Date();

    // Map all fields to data
    this.formModel.id = this.data.individualObject.id;
    this.formModel.Title = this.data.individualObject.Title;
    this.formModel.Grade = this.data.individualObject.Grade ? this.data.individualObject.Grade : 1;
    this.formModel.Year = this.data.individualObject.Year ? this.data.individualObject.Year : currDate.getFullYear();
    this.formModel.rumbles_quest_blocks = this.data.individualObject.rumbles_quest_blocks;
  }

  submitForm() {
    // Only include checked results
    this.formModel.rumbles_quest_blocks = this.formModel.rumbles_quest_blocks.filter(value => value.checked);

    this.groupsService.updateOrCreateGroup(this.type, this.formModel)
      .then(message => {
        this.alertService.show('Success! This individual has been updated.', 'success');
        this.bsModalRef.hide();
        console.log(message);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.bsModalRef.hide();
      });
    console.log(this.formModel);
  }

  createSelectedQuestBlocksArray() {
    const selected = [];
    this.data.individualObject.rumbles_quest_blocks.forEach(function (value) {
      selected.push(value.QuestBlockId);
    });
    console.log(selected);
    this.selectedQuestBlocks = selected;
  }

  public gradeChange(changeValue) {
    this.formModel.Grade = parseFloat(this.formModel.Grade) + parseFloat(changeValue);
  }
}
