import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Rx';

interface AppState {
  addedQuestBlockArray: Array<object>;
  questBlocks: Array<object>;
  questBlockFilter: Array<object>;
}

@Injectable()
export class QuestBlockFilteringService implements OnDestroy {
  public addedQuestBlockArray: Array<object> = [];
  public questBlocks: Array<object> = [];
  private questBlockFilteringSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.addedQuestBlockArray !== 'undefined') {
        this.addedQuestBlockArray = val.addedQuestBlockArray;
      }

      if (typeof val.questBlockFilter !== 'undefined') {
        this.questBlocks = val.questBlockFilter;
      }
    });
  }

  ngOnDestroy() {
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }
}
