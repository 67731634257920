import { Component, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ReportingService } from '../services/reporting.service';
import { AlertService } from '../alerts/alert.service';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-low-med-high-band-pie-chart',
  templateUrl: './low-med-high-band-pie-chart.component.html'
})

export class LowMedHighBandPieChartComponent implements OnChanges {
  @Input() public meanRating: any;
  @Input() public count: number;
  @Input() public tooltipEnabled = false;
  @Input() public dataLabelsEnabled = false;
  @Input() public colors: Array<string>;
  @Input() public categories: Array<string>;
  @Input() public keyHighlights: Array<any>;
  @Input() public series: Array<object>;
  @Input() public questBlocks: Array<object>;
  // @Input() public showKeyHighlightChildrenFunction: Function;
  // @Input() public toggleKeyHighlighted: Function;
  @Output() keyHighlightedState = new EventEmitter<boolean>();
  @Output() showKeyHighlightChildrenFunction = new EventEmitter<object>();

  public chart: Chart;
  public overallRatingCount = 0;
  public viewWhoLoading = false;
  public viewWhoStudents = [];
  public translations: any = {};

  constructor(
    private reportingService: ReportingService,
    private alertService: AlertService,
    private translationService: TranslationService
  ) {
    this.translations = translationService.getTranslations();
  }

  public ngOnChanges() {
    this.renderChart();
  }

  public viewWho(questionId, scoreBandArray, highlightObject) {
    this.keyHighlightedState.emit(true);
    this.showKeyHighlightChildrenFunction.emit({
      questionId: questionId,
      scoreBandArray: scoreBandArray,
      highlightObject: highlightObject
    });
  }

  private renderChart() {
    if (!this.meanRating) {
      return;
    }

    this.overallRatingCount = this.meanRating.overall;

    this.chart = new Chart({
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Grand Mean: ' + Number(this.meanRating.mean).toFixed(2),
        verticalAlign: 'middle',
        floating: true
      },
      xAxis: {
        categories: this.categories
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: this.tooltipEnabled
      },
      plotOptions: {
        pie: {
          innerSize: '65%'
        },
        series: {
          dataLabels: {
            enabled: this.dataLabelsEnabled
          }
        }
      },
      colors: this.colors,
      series: this.series
    });
  }

}
