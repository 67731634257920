import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

interface AppState {
    addedTagsArray: Array<object>;
    participantTags: Array<object>;
}

@Injectable()
export class ReportFilteringService implements OnDestroy {
    public addedTagsArray: Array<object> = [];
    public participantTags: Array<object> = [];
    private reportFilteringSubscription;

    constructor(private store: Store<AppState>) {
        // Subscribe to store and store in public property
        this.reportFilteringSubscription = this.store.subscribe(val => {
            if (typeof val.addedTagsArray !== 'undefined') {
              this.addedTagsArray = val.addedTagsArray;
            }

            if (typeof val.participantTags !== 'undefined') {
              this.participantTags = val.participantTags;
            }
        });
    }

    ngOnDestroy() {
      if (this.reportFilteringSubscription) {
          this.reportFilteringSubscription.unsubscribe();
      }
    }
}
