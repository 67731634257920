import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { QuestBlockFilteringService } from './quest-block-filtering.service';
import { ReportingService } from '../services/reporting.service';
import { TranslationService } from '../services/translation.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Store } from '@ngrx/store';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { PermissionsService } from '../services/permissions.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IndividualScoresModalComponent } from './partials/individual-scores-modal.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-child-scores-report',
  templateUrl: 'child-scores-report.component.html'
})
export class ChildScoresReportComponent implements OnInit, OnDestroy {
  public childScoresLoading = false;
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'school_student_id',
    'FullName',
    'Gender',
    'StudentGroups',
    'Age',
    'is_indigenous_readable',
    'totalWellbeingSubmissionDate',
    'totalWellbeingScorePercentileRelativeToPopulation',
    'attachmentToSchoolScorePercentileRelativeToPopulation',
    'selfRegulationScorePercentileRelativeToPopulation',
    'socialConfidenceScorePercentileRelativeToPopulation',
    'supportiveHomeScorePercentileRelativeToPopulation',
    'WMStandardScoreStandardScore',
    'inhibitionStandardisedScoreStandardScore',
    'cognitiveFlexibilityStandardisedScoreStandardScore',
    'attentionControlStandardisedScoreStandardScore',
    'focusedAttentionStandardisedScoreStandardScore',
  ];
  public questBlocks = [];
  public permissions = [];
  private questBlockFilteringSubscription: Subscription;
  public translations: any = {};
  public nameFilter = new FormControl();
  public gradeFilter = new FormControl();
  public groupsFilter = new FormControl();
  public ageFilter = new FormControl();
  public genderFilter = new FormControl();
  public indigenousFilter = new FormControl();
  private filterValues = {
    FullName: '',
    Grade: '',
    StudentGroups: '',
    Age: '',
    Gender: '',
    is_indigenous_readable: '',
  };
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalService: BsModalService,
    private _localeService: BsLocaleService,
    private store: Store<any>,
    private questBlockFilteringService: QuestBlockFilteringService,
    private translationService: TranslationService,
    private reportingService: ReportingService,
    private permissionService: PermissionsService,
    private authService: AuthService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
    this._localeService.use('en-gb');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue' });

    // Subscribe to store and store in public property
    this.questBlockFilteringSubscription = this.store.subscribe(val => {
      if (typeof val.questBlockFilter !== 'undefined') {
        this.questBlocks = val.questBlockFilter;
        this.refresh(); // refresh graph on change of quest block
      }
    });
  }

  ngOnInit() {
    this.questBlocks = this.questBlockFilteringService.questBlocks;
    this.refresh(); // init load
  }

  ngOnDestroy() {
    if (this.questBlockFilteringSubscription) {
      this.questBlockFilteringSubscription.unsubscribe();
    }
  }

  public refresh() {
    if (this.questBlocks.length === 0) {
      return; // only if quest blocks are set should it be run
    }

    this.childScoresLoading = true;

    const requestParams = [];

    this.questBlocks.forEach(function (val) {
      requestParams[val.name] = val.value;
    });

    this.dataSource = new MatTableDataSource<any>();
    this.createInitFilters();
    this.reportingService.getChildScores(requestParams).then(report => {
      this.dataSource = new MatTableDataSource<any>(report.childScoresData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.createInitFilters();
      this.childScoresLoading = false;
    });
  }

  openDialog(individual: object) {
    if (this.authService.getOrganisationType() !== 'agency') {
      return false;
    }

    const componentType = IndividualScoresModalComponent;
    const initialState: any = {
      title: 'View Individual',
      data: individual
    };
    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-xl'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  createInitFilters() {
    /**
     * Define Filters
     */
    this.nameFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['FullName'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.gradeFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['Grade'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.groupsFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['StudentGroups'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.ageFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['Age'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.genderFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['Gender'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    this.indigenousFilter.valueChanges
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(value => {
        this.filterValues['is_indigenous_readable'] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
      });

    // custom filterPredicate function:
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter(): (data: any, filter: string) => boolean {
    return function (data, filter): boolean {
      if (typeof data !== 'object' || data === null) {
        return false;
      }

      const searchTerms = JSON.parse(filter);
      const genderSearch = data.Gender !== null ? data.Gender.toString().toLowerCase() === searchTerms.Gender.trim().toLowerCase() : false;
      const ageSearch = data.AgeAtSubmission !== null ? data.AgeAtSubmission.toString().trim().toLowerCase().indexOf(searchTerms.Age.trim().toLowerCase()) !== -1 : false;
      const groupsSearch = data.StudentGroups !== null ? data.StudentGroups.toString().trim().toLowerCase().indexOf(searchTerms.StudentGroups.trim().toLowerCase()) !== -1 : false;
      const indigenousSearch = data.is_indigenous_readable !== null ? data.is_indigenous_readable.toString().trim().toLowerCase().indexOf(searchTerms.is_indigenous_readable.trim().toLowerCase()) !== -1 : false;
      const nameSearch = () => {
        let found = false;

        searchTerms.FullName.trim().toLowerCase().split(' ').forEach(word => {
          // console.log(word);
          // console.log(data.FullName);
          if (data.FullName.toLowerCase().indexOf(word) !== -1) {
            found = true;
          }
        });

        return found;
      };

      return nameSearch() && groupsSearch && indigenousSearch && ageSearch;
    };
  }

  wellbeingShowRedFlag(standardScore, percentileVulnerable) {
    if (standardScore <= percentileVulnerable) {
      return true;
    }

    return false;
  }

  displayWellbeingScore(item) {
    if (item < 0 || item > 100) {
      return 'Out of bounds';
    }

    return this.round(item, 1);
  }

  executiveFunctionIsMoreThanExpected(item) {
    if (item > 40 && item <= 100) {
      return true;
    }

    return false;
  }

  executiveFunctionIsLessThanExpected(item) {
    if (item <= 40 && item > 0) {
      return true;
    }

    return false;
  }

  displayExecutiveFunctionScore(item) {
    if (item < 0 || item > 100) {
      return 'Out of bounds';
    }

    return this.round(item, 1);
  }

  exportCsv() {
    const allowed = this.displayedColumns;
    const exportableData = this.dataSource.data.map(function(val, index) {
      // only allowed cols
      const filtered: any = Object.keys(val)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = val[key];
          return obj;
        }, {});

      // return custom order
      return {
        school_student_id: filtered.school_student_id,
        FullName: filtered.FullName,
        Gender: filtered.Gender,
        StudentGroups: filtered.StudentGroups,
        Age: filtered.Age,
        is_indigenous_readable: filtered.is_indigenous_readable,
        totalWellbeingSubmissionDate: filtered.totalWellbeingSubmissionDate,
        totalWellbeingScorePercentileRelativeToPopulation: filtered.totalWellbeingScorePercentileRelativeToPopulation,
        attachmentToSchoolScorePercentileRelativeToPopulation: filtered.attachmentToSchoolScorePercentileRelativeToPopulation,
        selfRegulationScorePercentileRelativeToPopulation: filtered.selfRegulationScorePercentileRelativeToPopulation,
        socialConfidenceScorePercentileRelativeToPopulation: filtered.socialConfidenceScorePercentileRelativeToPopulation,
        supportiveHomeScorePercentileRelativeToPopulation: filtered.supportiveHomeScorePercentileRelativeToPopulation,
        WMStandardScoreStandardScore: filtered.WMStandardScoreStandardScore,
        inhibitionStandardisedScoreStandardScore: filtered.inhibitionStandardisedScoreStandardScore,
        cognitiveFlexibilityStandardisedScoreStandardScore: filtered.cognitiveFlexibilityStandardisedScoreStandardScore,
        attentionControlStandardisedScoreStandardScore: filtered.attentionControlStandardisedScoreStandardScore,
        focusedAttentionStandardisedScoreStandardScore: filtered.focusedAttentionStandardisedScoreStandardScore,
      };
    });

    const csv = new Angular5Csv(
      exportableData,
      'Child Scores Export',
      {
        showLabels: true,
        headers: [
          'ID',
          'Name',
          'Gender',
          'Groups',
          'Submission Age',
          'First Peoples',
          'Submission Date',
          'Overall Wellbeing',
          'Attachment to School',
          'Self-Regulation',
          'Social Confidence',
          'Supportive Relationships',
          'Working Memory',
          'Inhibitory Control',
          'Cognitive Flexibility',
          'Attention Control',
          'Focused Attention',
        ]
      }
    );
  }

  private round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
