import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OrganisationsService } from '../services/organisations.service';
import { UserService } from '../services/user.service';
import { AlertService } from '../alerts/alert.service';

@Component({
  selector: 'app-school-tas-create-user-from-invitation',
  templateUrl: './school-tas-create-user-from-invitation.component.html'
})
export class SchoolTasCreateUserFromInvitationComponent implements OnInit {
  public inviteQueryParams = {
    token: null
  };
  public invitation = null;
  public formModel: any = {};
  public loadingInvitation = false;
  public submitting = false;

  constructor(
    private route: ActivatedRoute,
    private organisationService: OrganisationsService,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.inviteQueryParams.token = params.get('token');

      if (this.inviteQueryParams.token !== null) {
        this.getInvitationOrganisationUserByToken();
      }
    });
  }

  getInvitationOrganisationUserByToken() {
    this.loadingInvitation = true;
    this.organisationService.getInvitationOrganisationUserByToken(this.inviteQueryParams.token)
      .then(data => {
        console.log(data);
        this.invitation = data;
        this.loadingInvitation = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.loadingInvitation = false;
      });
  }

  submitForm() {
    if (typeof this.invitation.organisation === 'undefined') {
      this.alertService.show('Error! No organisation has been detected. Please ensure the URL is correct, or that you have selected an organisation.', 'danger');
    }

    this.formModel.organisation_id = this.invitation.organisation.id;
    this.formModel.token = this.invitation.token;
    this.formModel.contact_is_authority = true;

    this.submitting = true;

    this.userService.createUserFromInvitationOrganisation(this.formModel)
      .then(message => {
        this.alertService.show('Success! You have been registered. Please check your email and click on the link to confirm your account.', 'success');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.submitting = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.submitting = false;
        const alertService = this.alertService;

        if (typeof msg.error.message !== 'undefined') {
          alertService.show(msg.error.message, 'danger');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return true;
        }

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }
}
