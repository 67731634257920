import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class IndividualsService {
  constructor(private readonly apiService: ApiService) {}

  public getIndividuals(): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/individuals/all`)
      .toPromise<any>();
  }

  public getIndividualsArchived(): Promise<any> {
    return this.apiService
        .get<any>(`api/rumbles/individuals/all/archived`)
        .toPromise<any>();
  }

  public getIndividualsInQuestBlocks(requestParams): Promise<any> {
    return this.apiService
      .get<any>(
        `api/rumbles/individuals/quest-block`,
        {
          params: requestParams
        })
      .toPromise<any>();
  }

  public updateOrCreateIndividual(type, data): Promise<any> {
    let url;

    if (type === 'add') {
      url = `api/rumbles/individuals/create`;
    } else if (type === 'edit') {
      url = `api/rumbles/individuals/update`;
    }

    return this.apiService
      .post<any>(url, data)
      .toPromise<any>();
  }

  public archiveIndividual(data): Promise<any> {
    return this.apiService
        .post<any>(`api/rumbles/individuals/archive`, data)
        .toPromise<any>();
  }

  public unarchiveIndividual(data): Promise<any> {
    return this.apiService
        .post<any>(`api/rumbles/individuals/unarchive`, data)
        .toPromise<any>();
  }

  public bulkImportIndividuals(finalObject): Promise<any> {
    return this.apiService
      .post<any>(
        `api/rumbles/individuals/bulk-import`,
        finalObject
      )
      .toPromise<any>();
  }
}
