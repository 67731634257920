import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslationService } from '../services/translation.service';
import { AlignResponsesService } from '../services/align-responses.service';
import { AlertService } from '../alerts/alert.service';
import { QuestBlockModalComponent } from './partials/quest-block-modal.component';
import { AlignResponsesEditModalComponent } from './partials/align-responses-edit-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-align-responses',
  templateUrl: 'align-responses.component.html'
})
export class AlignResponsesComponent implements OnInit, OnDestroy {
  private sub: any;
  public questBlockKey: string;
  public translations: any = {};
  public permissions = [];
  bsModalRef: BsModalRef;
  bsConfig: Partial<BsDatepickerConfig>;
  displayedColumns = ['Student', 'QuestBlockKey', 'Age', 'ProcessedTime', 'Change', 'Confirm'];
  alignedDataSource = new MatTableDataSource();
  unAlignedDataSource = new MatTableDataSource();
  alignedSelection = new SelectionModel(true, []);
  unalignedSelection = new SelectionModel(true, []);
  loading = true;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private alignResponsesService: AlignResponsesService,
    private alertService: AlertService,
    private permissionService: PermissionsService
  ) {
    this.translations = translationService.getTranslations();
    this.permissions = this.permissionService.getPermissions();
    this.modalService.onHide.subscribe(() => this.refresh()); // refresh on close modal
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.questBlockKey = params['key'];
    });

    this.loading = true;
    this.refresh();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  refresh() {
    this.alignResponsesService.getSubmissionsByQuestBlock(this.questBlockKey)
      .then(questBlocks => {
        this.alignedDataSource = new MatTableDataSource(questBlocks.aligned);
        this.unAlignedDataSource = new MatTableDataSource(questBlocks.unaligned);
        this.loading = false;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        this.loading = false;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  confirmAllUnaligned() {
    this.alignResponsesService.confirmAllUnaligned(this.questBlockKey)
      .then(data => {
        this.alertService.show('Successfully aligned all unaligned for this quest block that have matches. It will take up to 15 mins to appear in Child Scores while we calculate their scores.', 'success');
        this.refresh();
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  confirmResponse(element) {
    this.alignResponsesService.confirmIndividualUnaligned(this.questBlockKey, element.submission.SubmissionId)
      .then(data => {
        this.alertService.show('Successfully aligned individual unaligned for this quest block that have matches. It will take up to 15 mins to appear in Child Scores while we calculate their scores.', 'success');
        this.refresh();
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }

  applyAlignedFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.alignedDataSource.filter = filterValue;
  }

  applyUnalignedFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.unAlignedDataSource.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedAligned() {
    const numSelected = this.alignedSelection.selected.length;
    const numRows = this.alignedDataSource.data.length;
    return numSelected === numRows;
  }
  isAllSelectedUnaligned() {
    const numSelected = this.unalignedSelection.selected.length;
    const numRows = this.unAlignedDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleAligned() {
    this.isAllSelectedAligned() ?
      this.alignedSelection.clear() :
      this.alignedDataSource.data.forEach(row => this.alignedSelection.select(row));
  }
  masterToggleUnaligned() {
    this.isAllSelectedUnaligned() ?
      this.unalignedSelection.clear() :
      this.unAlignedDataSource.data.forEach(row => this.unalignedSelection.select(row));
  }

  openDialog(alignResponsesBlockObject: object, type: string) {
    const componentType = AlignResponsesEditModalComponent;
    const initialState: any = {
      data: {
        alignResponsesBlockObject: {}
      },
      type: type,
      title: 'Manage Response'
    };

    if (type === 'edit') {
      initialState.title = 'Edit Response';
      initialState.data.alignResponsesBlockObject = alignResponsesBlockObject;
    } else if (type === 'add') {
      initialState.title = 'Add Response';
    }

    this.bsModalRef = this.modalService.show(componentType, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
