import { ActionWithPayload } from '../interfaces/action-with-payload';

export const ADD_PARTICIPANT_TAGS = 'ADD_PARTICIPANT_TAGS';
export const REMOVE_PARTICIPANT_TAGS = 'REMOVE_PARTICIPANT_TAGS';
export const RESET_PARTICIPANT_TAGS = 'RESET_PARTICIPANT_TAGS';
export const PUSH_PARTICIPANT_TAGS = 'PUSH_PARTICIPANT_TAGS';

export function participantTagsReducer(state: Array<object>, action: ActionWithPayload) {
  switch (action.type) {
    case ADD_PARTICIPANT_TAGS:
      state = action.payload;

      return state;

    case PUSH_PARTICIPANT_TAGS:
      if (typeof state === 'undefined') {
        state = [];
      }

      state.push(action.payload);

      return state;

    case REMOVE_PARTICIPANT_TAGS:
      // TODO
      return state;

    case RESET_PARTICIPANT_TAGS:
      return [];

    default:
      return state;
  }
}
