import { Injectable } from '@angular/core';
import { translations } from '../constants/translations.constant';
import { AuthService } from './auth.service';

@Injectable()
export class TranslationService {
  public translations = translations;

  constructor(private authService: AuthService) {}

  /**
   * Get Translations for relevant organisation type
   * @returns {object}
   */
  public getTranslations(): object {
    const userProfile = this.authService.getUserProfile();

    // Safe-guard checks
    if (
      userProfile === null ||
      typeof userProfile.organisation.type === 'undefined' ||
      (userProfile.organisation.type.toLowerCase() !== 'school' &&
      userProfile.organisation.type.toLowerCase() !== 'agency')
    ) {
      return this.translations['school'];
    }

    const type = userProfile.organisation.type.toLowerCase();

    return this.translations[type]; // todo: dont assume school, get based on login
  }

  public getUserType(): string {
    const userProfile = this.authService.getUserProfile();

    if (
      userProfile === null ||
      typeof userProfile.organisation.type === 'undefined' ||
      (userProfile.organisation.type.toLowerCase() !== 'school' &&
        userProfile.organisation.type.toLowerCase() !== 'agency')
    ) {
      return null;
    }

    return userProfile.organisation.type.toLowerCase();
  }
}
