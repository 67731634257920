import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newline'
})
export class NewlinePipe implements PipeTransform {
  public transform(value: string, args: string[]): any {
    if (!value) {
      return '';
    }

    return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
}
