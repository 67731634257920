import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-low-med-high-band-bar-chart',
  templateUrl: './low-med-high-band-bar-chart.component.html'
})

export class LowMedHighBandBarChartComponent implements OnInit, OnChanges {
  @Input() public categories: Array<string>;
  @Input() public colors: Array<string>;
  @Input() public labels = true;
  @Input() public series: Array<object>;
  @Input() public triggerChange: number;

  public chart: Chart;

  private maxScore = 100;
  private minScore = 0;

  ngOnInit() {
    this.renderChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderChart();
  }

  public renderChart() {
    const chartOptions = {
      colors: this.colors,
      chart: {
        backgroundColor: '#fff',
        spacingBottom: 50,
        type: 'column',
        width: 1090,
        height: 500
      },
      tooltip: {
        enabled: true,
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.0f}</b><br/>',
        shared: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        useHTML: true,
        animation: true,
        padding: 10,
        headerFormat: '<b>{point.x}</b><br/>'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 50,
        enabled: true,
        floating: true,
        shadow: false,
        symbolRadius: 50,
        symbolWidth: 15,
        symbolHeight: 15,
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      yAxis: {
        min: this.minScore,
        max: this.maxScore,
        tickInterval: 50,
        title: {
          text: 'Score',
          style: {
            fontSize: '14px'
          }
        }
      },
      xAxis: {
        categories: this.categories
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            color: '#000',
            shadow: false,
            borderWidth: 0,
            formatter: function () {
              return Highcharts.numberFormat(this.y, 1);
            },
            style: {
              textOutline: '0',
              fontSize: '14px'
            }
          }
        },
        spline: {
          dataLabels: {
            enabled: false
          }
        }
      },
      series: this.series
    };

    if (!this.labels) {
      chartOptions.tooltip.enabled = false;
      chartOptions.plotOptions.column.dataLabels.enabled = false;
      chartOptions.plotOptions.spline.dataLabels.enabled = false;
    }

    this.chart = new Chart(chartOptions);
  }
}
